import permission from "../../permission";
import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import useAxiosApiPacs from "../../hooks/useAxiosApiPacs";
import utils from "../../utils";
import { endPoint } from "../../api/axios";
import format from "date-fns/format";
import { useDispatch } from "react-redux";
import { openDialog } from "../../redux/confirm";

import Restricted from "../../components/Restricted";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DiagnosticResultTemplateDialog from "./diagnosticResultTemplateModal";

const DiagnosticResultTemplate = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selecting, setSelecting] = useState({});
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const axios = useAxiosApiPacs();

  const columns = [
    {
      field: "templateName",
      headerName: "Template Name",
      minWidth: 300,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      minWidth: 200,
      valueGetter: (params) => {
        let date = params.row.createdDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      minWidth: 150,
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      minWidth: 200,
      valueGetter: (params) => {
        let date = params.row.createdDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ justifyContent: "space-evenly", display: "flex" }}>
            <Box paddingRight="10px">
              <IconButton
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[500],
                  ":hover": {
                    backgroundColor: colors.blueAccent[600],
                  },
                }}
                onClick={() => {
                  handleViewRow(params.row);
                }}
                size="small"
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Box>
            <Box paddingRight="10px">
              <Restricted to={permission.update.diagnosticTemplate}>
                <IconButton
                  sx={{
                    color: colors.grey[100],
                    backgroundColor: colors.greenAccent[500],
                    ":hover": {
                      backgroundColor: colors.greenAccent[600],
                    },
                  }}
                  onClick={() => {
                    handleUpdateRow(params.row);
                  }}
                  size="small"
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Restricted>
            </Box>
            <Box paddingRight="10px">
              <Restricted to={permission.delete.diagnosticTemplate}>
                <IconButton
                  sx={{
                    color: colors.grey[100],
                    backgroundColor: colors.redAccent[500],
                    ":hover": {
                      backgroundColor: colors.redAccent[600],
                    },
                  }}
                  onClick={() => {
                    handleDeleteRow(params.row);
                  }}
                  size="small"
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Restricted>
            </Box>
          </Box>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();
    try {
      const response = await axios.get(endPoint.DiagosticResultTemplate, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNewRow = () => {
    var template = {
      templateId: "",
      templateName: "",
      templateContent: "",
    };
    setDataDialog(template, "create");
  };

  const handleViewRow = async (row) => {
    var template = await getTemplateById(row.templateId);
    setDataDialog(template, "view");
  };

  const handleUpdateRow = async (row) => {
    var template = await getTemplateById(row.templateId);
    setDataDialog(template, "update");
  };

  const getTemplateById = async (id) => {
    try {
      let url = endPoint.DiagosticResultTemplate + "/" + id;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      return response.data;
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
    }
  };

  const handleDeleteRow = (row) => {
    let url = endPoint.DiagosticResultTemplate + `/${row.templateId}`;

    dispatch(
      openDialog({
        title: "Delete Template",
        content: `Are you sure you want to Delete Template : "${row.templateName}" ?`,
        onSubmit: async () => {
          try {
            debugger;
            const response = await axios.delete(url);
            if (response?.status === 200) {
              utils.showMessage("Delete Succeeded", "success");
              deleteRow(row.templateId);
            }
          } catch (err) {
            if (!err?.response) {
              utils.showMessage("Server not response", "error");
            } else {
              utils.showMessage(err.response.data, "error");
            }
            console.log(err);
          }
        },
      })
    );
  };

  const setDataDialog = (row, mode) => {
    setSelecting({
      mode: mode,
      templateId: row.templateId,
      templateName: row.templateName,
      templateContent: row.templateContent,
      open: true,
    });
  };

  const deleteRow = (id) => {
    apiRef.current.updateRows([{ templateId: id, _action: "delete" }]);
  };

  return (
    <Box>
      <Box display="flex">
        <Restricted to={permission.create.diagnosticTemplate}>
          <Button
            variant="outlined"
            startIcon={<NoteAddOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            onClick={handleNewRow}
          >
            New
          </Button>
        </Restricted>
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
      </Box>
      <Box
        height={"calc(100vh - 235px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
          "& .MuiDataGrid-cell--editing:has(> .Mui-disabled)": {
            background: `${colors.redAccent[800]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            overflow: "auto",
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.templateId}
          autoPageSize
        />
        <DiagnosticResultTemplateDialog
          data={selecting}
          setData={setSelecting}
          onClose={fetchData}
        />
      </Box>
    </Box>
  );
};

export default DiagnosticResultTemplate;
