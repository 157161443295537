import cornerstoneTools from 'cornerstone-tools';

export const tags = {
  PatientId: "00100020",
  PatientName: "00100010",
  PatientBirthDate: "00100030",
  StudyDescription: "00081030",
  ModalitiesInStudy: "00080061",
  AccessionNumber: "00080050",
  StudyDate: "00080020",
  StudyInstanceUID: "0020000D",
  NumberOfStudyRelatedSeries: "00201206",
  SeriesInstanceUID: "0020000E",
  SeriesNumber: "00200011",
  SeriesDescription: "0008103E",
  NumberOfInstanceInSeries: "00201209",
  RetrieveURL: "00081190",
  InstanceUID: "00080018",
  InstanceNumber: "00200013",
};

export const level = {
  Studies: "studies",
  Series: "series",
  Instances: "instances",
};

export const tools = [
  cornerstoneTools.WwwcTool,
  cornerstoneTools.PanTool,
  cornerstoneTools.ZoomTool,
  cornerstoneTools.ZoomMouseWheelTool,
  cornerstoneTools.RotateTool,
  cornerstoneTools.LengthTool,
  cornerstoneTools.MagnifyTool,
  cornerstoneTools.AngleTool,
  cornerstoneTools.ArrowAnnotateTool,
  cornerstoneTools.BidirectionalTool,
  cornerstoneTools.CobbAngleTool,
  cornerstoneTools.EllipticalRoiTool,
  cornerstoneTools.ProbeTool,
  cornerstoneTools.RectangleRoiTool,
  cornerstoneTools.TextMarkerTool,
  cornerstoneTools.EraserTool,
  cornerstoneTools.StackScrollTool,
];
