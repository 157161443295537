import { format, parse } from "date-fns";
import { enqueueSnackbar } from "notistack";

const toViewerDate = (date) => {
  try {
    let parseDate = parse(date, "yyyyMMdd", new Date());
    return format(parseDate, "MM/dd/yyyy");
  } catch (err) {
    console.log(err);
    return format(new Date(), "YYYYMMDD");
  }
};

const convertDate = (date, strFormat = "yyyy-MM-dd") => {
  if (!date) {
    return;
  }
  try {
    const result = format(date, strFormat);
    return result;
  } catch (err) {
    console.log(err);
  }

  return;
};

const toServerDate = (date) => {
  try {
    return format(new Date(date), "yyyyMMdd");
  } catch (err) {
    console.log(err);
    return format(new Date(), "YYYYMMDD");
  }
};

const parseDateString = (str) => {
  if (!/^(\d){8}$/.test(str)) return "invalid date";
  var y = str.substr(0, 4),
    m = str.substr(4, 2),
    d = str.substr(6, 2);
  return new Date(y, m, d).toDateString();
};

const showMessage = (message, variant) => {
  enqueueSnackbar(message, {
    variant: variant,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    autoHideDuration: 5000,
  });
};

const buildUrlParams = (params) => {
  return "?" + new URLSearchParams(params).toString();
};

const search = (items, searchParam, query) => {
  return items?.filter((item) => {
    return searchParam.some((newItem) => {
      return (
        item[newItem].toString().toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    });
  });
};

const checkRegex = (text, regex) => {
  return regex.test(text);
};

const checkContains = (array, value) => {
  if (!value) return true;
  return array?.some((val) => {
    if (val.trim() === value.trim()) return true;
    return false;
  });
};

const formatDate = (date, strFormat = "MMM d, yyyy") => {
  // 'Apr 5, 1999'
  if (!date) {
    return;
  }
  try {
    const parsedDateTime = parse(date, "yyyyMMdd", new Date());
    const formattedDateTime = format(parsedDateTime, strFormat);

    return formattedDateTime;
  } catch (err) {
    console.log(err);
  }

  return;
};

const formatNumberPrecision = (number, precision) => {
  if (number !== null) {
    return parseFloat(number).toFixed(precision);
  }
};

const formatPatientName = (name) => {
  if (!name) {
    return;
  }

  // Convert the first ^ to a ', '. String.replace() only affects
  // the first appearance of the character.
  const commaBetweenFirstAndLast = name.replace("^", ", ");

  // Replace any remaining '^' characters with spaces
  const cleaned = commaBetweenFirstAndLast.replace(/\^/g, " ");

  // Trim any extraneous whitespace
  return cleaned.trim();
};

const formatTime = (time, strFormat = "HH:mm:ss") => {
  if (!time) {
    return;
  }
  // DICOM Time is stored as HHmmss.SSS, where:
  //      HH 24 hour time:
  //      m mm    0..59   Minutes
  //      s ss    0..59   Seconds
  //      S SS SSS    0..999  Fractional seconds
  //
  // Goal: '24:12:12'
  try {
    const inputFormat = "HHmmss.SSS";
    const strTime = time.toString().substring(0, inputFormat.length);
    const parsedDateTime = parse(
      strTime,
      inputFormat.substring(0, strTime.length),
      new Date(0)
    );
    const formattedDateTime = format(parsedDateTime, strFormat);

    return formattedDateTime;
  } catch (err) {
    console.log(err);
  }

  return;
};

const isValidNumber = (value) => {
  return typeof value === "number" && !isNaN(value);
};

const isValidString = (value) => {
  return value !== undefined && value !== "" && value !== null;
};

const utils = {
  toViewerDate,
  toServerDate,
  parseDateString,
  showMessage,
  buildUrlParams,
  search,
  checkRegex,
  checkContains,
  formatDate,
  formatNumberPrecision,
  formatPatientName,
  formatTime,
  isValidNumber,
  isValidString,
  convertDate,
};

export default utils;
