import { useEffect, useState, useCallback } from "react";
import { endPoint, SYSTEM_ID } from "../../api/axios";
import useAxiosAdmin from "../../hooks/useAxiosAdmin";
import utils from "../../utils";
import format from "date-fns/format";

//UI
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Box, Button, useTheme } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const ActionsGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const axios = useAxiosAdmin();

  const columns = [
    {
      field: "actionName",
      headerName: "Action Name *",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      valueGetter: (params) => {
        let date = params.row.createdDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      flex: 1,
      valueGetter: (params) => {
        let date = params.row.updatedDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();
    try {
      let url = endPoint.ActionInfo + `/${SYSTEM_ID}/Client`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box>
      <Box display="flex">
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
      </Box>
      <Box
        height={"calc(100vh - 235px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.actionId}
          autoPageSize
        />
      </Box>
    </Box>
  );
};

export default ActionsGrid;
