import { createSlice } from "@reduxjs/toolkit";

export const diagnosticSlice = createSlice({
  name: "diagnostic",
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleDiagnostic: (state) => {
      state.isOpen = !state.isOpen;
    },
    setCollapseDiagnostic: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { toggleDiagnostic, setCollapseDiagnostic } =
  diagnosticSlice.actions;
export default diagnosticSlice.reducer;
