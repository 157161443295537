import { tokens } from "../../../theme";
import { useEffect, useState } from "react";
import { endPoint } from "../../../api/axios";
import useAxiosApiPacs from "../../../hooks/useAxiosApiPacs";
import utils from "../../../utils";
import TemplateBox from "./TemplateBox";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const DiagnosticResultTemplateDialog = ({ data, setData, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosApiPacs();
  const [templateName, setTemplateName] = useState("");
  const [errTemplateName, setErrTemplateName] = useState("");

  useEffect(() => {
    setTemplateName(data.templateName);
  }, [data.templateName]);

  const toolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "history",
    ],
    inline: {
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "superscript",
        "subscript",
      ],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
    fontsize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60],
    },
    textAlign: {
      options: ["left", "center", "right"],
    },
  };

  const closeDialog = () => {
    setData((prev) => ({
      ...prev,
      open: false,
    }));
    setErrTemplateName("");
  };

  var submitMethod = {};

  const handleSubmit = async (obj) => {
    if (templateName === undefined || templateName.trim().length === 0) {
      setErrTemplateName("Template Name Must Have Value !");
      return;
    }
    debugger;
    var entity = {
      templateId: obj.id,
      templateName: templateName,
      templateContent: obj.content,
    };

    try {
      switch (data.mode) {
        case "create":
          await axios.post(endPoint.DiagosticResultTemplate, entity, {
            headers: {
              "Content-Type": "application/json;",
            },
          });
          utils.showMessage("Create Template Succeeded", "success");
          break;
        case "update":
          await axios.put(endPoint.DiagosticResultTemplate, entity, {
            headers: {
              "Content-Type": "application/json;",
            },
          });
          utils.showMessage("Update Template Succeeded", "success");
          break;
        default:
          break;
      }

      if (data.mode !== "view") {
        onClose();
      }
      closeDialog();
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  const renderDialogAction = (mode) => {
    return (
      <DialogActions>
        {mode !== "view" ? (
          <Button
            onClick={() => submitMethod.updateTemplate()}
            startIcon={<CheckOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            variant="outlined"
          >
            Save
          </Button>
        ) : (
          <></>
        )}

        <Button
          onClick={() => closeDialog()}
          startIcon={<CloseOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[600],
            ":hover": {
              backgroundColor: colors.redAccent[700],
            },
          }}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog
      open={data.open}
      onClose={() => closeDialog()}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle variant="h3">
        {data.mode === "update" ? (
          <>Update Template</>
        ) : data.mode === "create" ? (
          <>Create New Template</>
        ) : (
          <>Template {data.templateName}</>
        )}
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => closeDialog()}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          "& .MuiCheckbox-root.Mui-checked": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {data.mode !== "view" ? (
          <Box p={2}>
            <TextField
              fullWidth
              defaultValue={data.templateName}
              onChange={(e) => setTemplateName(e.currentTarget.value)}
              error={!!errTemplateName}
              helperText={errTemplateName}
              size="small"
              variant="outlined"
              label="Template Name"
              color="secondary"
            />
          </Box>
        ) : (
          <></>
        )}

        <Box p={2}>
          <TemplateBox
            data={data}
            defaultEdit={data.mode !== "view"}
            toolbar={toolbar}
            submitMethod={submitMethod}
            callBackSubmit={handleSubmit}
          />
        </Box>
      </DialogContent>
      {renderDialogAction(data.mode)}
    </Dialog>
  );
};

export default DiagnosticResultTemplateDialog;
