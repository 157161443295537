import { Formik } from "formik";
import * as yup from "yup";
import { endPoint } from "../../api/axios";
import useAxiosAdmin from "../../hooks/useAxiosAdmin";
import { Link } from "react-router-dom";

import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Box,
  LinearProgress,
  useTheme,
} from "@mui/material";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { tokens } from "../../theme";
import { useState } from "react";

const schema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Required"),
});

const ForgotPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosAdmin();
  const [isDone, setIsDone] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 500,
    margin: "auto",
  };

  const handleSubmitForm = async (values) => {
    setError("");
    setEmail(values.email);
    setIsProcessing(true);
    let url = endPoint.AccountInfo + "/ForgotPassword";
    let obj = {
      email: values.email,
    };
    try {
      const response = await axios.post(url, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      setIsDone(response.status === 200);
    } catch (err) {
      console.log(err);
      setError(err.response.data);
    }
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      {isDone ? (
        <Box>
          <Grid align="center">
            <Avatar
              sx={{
                backgroundColor: colors.greenAccent[500],
                height: "120px",
                width: "120px",
              }}
            >
              <MarkEmailReadOutlinedIcon sx={{ fontSize: 100 }} />
            </Avatar>
            <h2>Email Confirmation</h2>
          </Grid>
          <Typography variant="p">
            We have sent an email to {email} to confirm reset your account
            password. After receiving the email, follow the link provided to
            complete reset your account password.
          </Typography>
        </Box>
      ) : (
        <>
          <Grid align="center">
            <Avatar style={{ backgroundColor: colors.blueAccent[500] }}>
              <LockResetOutlinedIcon />
            </Avatar>
            <h2>Reset Password</h2>
          </Grid>
          <Box>
            <Typography variant="p">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </Typography>
            <Box sx={{ paddingTop: 2 }}>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={schema}
                onSubmit={handleSubmitForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Email"
                        name="email"
                        placeholder="Enter your email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                      <Typography variant="p" sx={{ color: "red" }}>
                        {error}
                      </Typography>
                    </Box>
                    <Box sx={{ paddingTop: 1 }}>
                      <Button
                        fullWidth={true}
                        type="submit"
                        sx={{
                          color: colors.primary[100],
                          backgroundColor: colors.blueAccent[500],
                          ":hover": {
                            backgroundColor: colors.blueAccent[600],
                          },
                        }}
                        variant="outlined"
                        disabled={isProcessing}
                      >
                        Continue
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
              <Box
                sx={{
                  marginTop: "1em",
                }}
              >
                {isProcessing ? <LinearProgress /> : <></>}
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Box paddingTop={1}>
        <Typography>
          <Link to="/login">Return to Login ?</Link>
        </Typography>
      </Box>
    </Paper>
  );
};

export default ForgotPassword;
