import { Outlet } from "react-router-dom";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "../pages/global/Topbar";
import ConfirmDialog from "./ConfirmDialog";

const Layout = () => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className="app">
          <div className="content">
            <ConfirmDialog />
            <Topbar />
            <Outlet />
          </div>
        </main>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Layout;
