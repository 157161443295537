import cornerstoneTools from "cornerstone-tools";

export const tools = [
  cornerstoneTools.WwwcTool,
  cornerstoneTools.PanTool,
  cornerstoneTools.ZoomTool,
  cornerstoneTools.ZoomMouseWheelTool,
  cornerstoneTools.RotateTool,
  cornerstoneTools.LengthTool,
  cornerstoneTools.MagnifyTool,
  cornerstoneTools.AngleTool,
  cornerstoneTools.ArrowAnnotateTool,
  cornerstoneTools.BidirectionalTool,
  cornerstoneTools.CobbAngleTool,
  cornerstoneTools.EllipticalRoiTool,
  cornerstoneTools.ProbeTool,
  cornerstoneTools.RectangleRoiTool,
  cornerstoneTools.TextMarkerTool,
  cornerstoneTools.EraserTool,
  cornerstoneTools.StackScrollTool,
];

export const toolNames = [
  "Wwwc",
  "Rotate",
  "Magnify",
  "Angle",
  "ArrowAnnotate",
  "Bidirectional",
  "CobbAngle",
  "EllipticalRoi",
  "Length",
  "Probe",
  "RectangleRoi",
  "TextMarker",
  "StackScroll",
];
