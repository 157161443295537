import { useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import { Box, Tabs, Tab } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";

const MyAccount = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChangeValue = (e, value) => {
    setValue(value);
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tab-panel-${index}`,
    };
  };

  return (
    <Box m="20px">
      <Header title={t("account")} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeValue}
          aria-label="Profile Tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label={t("profile")} {...a11yProps(0)} />
          <Tab label={t("changePassword")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePassword />
      </TabPanel>
    </Box>
  );
};

export default MyAccount;
