import { Box, Button, IconButton, useTheme } from "@mui/material";
import { DataGrid, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignActions from "./assignActions";

import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint, SYSTEM_ID } from "../../api/axios";
import useAxiosAdmin from "../../hooks/useAxiosAdmin";
import utils from "../../utils";
import { useDispatch } from "react-redux";
import { openDialog } from "../../redux/confirm";
import Restricted, { useRestricted } from "../../components/Restricted";
import permission from "../../permission";
import format from "date-fns/format";

const FunctionGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiRef = useGridApiRef();
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assignAct, setAssignAct] = useState({
    functionId: "",
    functionName: "",
    data: [],
    open: false,
  });

  const dispatch = useDispatch();
  const axios = useAxiosAdmin();
  const isEdit = useRestricted(permission.update.function);

  const columns = [
    {
      field: "functionName",
      headerName: "Function Name *",
      editable: isEdit,
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        let date = params.row.createdDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      editable: false,
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        let date = params.row.updatedDate;
        if (date) return format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      },
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
      editable: false,
    },
    {
      field: "manage",
      headerName: "Manage",
      editable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Restricted to={permission.create.actionsFunction}>
            <Button
              variant="outlined"
              startIcon={<AssignmentOutlinedIcon />}
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.greenAccent[500],
                ":hover": {
                  backgroundColor: colors.greenAccent[600],
                },
              }}
              onClick={() => {
                handleAssignAction(params.row);
              }}
              disabled={params.row.isNew ? true : false}
            >
              Assign Actions
            </Button>
          </Restricted>
        );
      },
    },
    {
      field: "status",
      headerName: "Action",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Restricted to={permission.delete.function}>
            <IconButton
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.redAccent[500],
                ":hover": {
                  backgroundColor: colors.redAccent[600],
                },
              }}
              onClick={() => {
                handleDeleteRow(params.row);
              }}
              disabled={params.row.isNew ? true : false}
              size="small"
            >
              <DeleteOutlinedIcon />
            </IconButton>
          </Restricted>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const controller = new AbortController();
    try {
      let url = endPoint.FunctionInfo + `/${SYSTEM_ID}/Client`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setRowData(response.data);
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteRow = async (row) => {
    let url = endPoint.FunctionInfo + `/${row.functionId}`;

    dispatch(
      openDialog({
        title: "Delete Function",
        content: `Are you sure you want to Delete Function : "${row.functionName}" ?`,
        onSubmit: async () => {
          try {
            const response = await axios.delete(url);
            if (response?.status === 200) deleteRow(row.functionId);
          } catch (err) {
            if (!err?.response) {
              utils.showMessage("Server not response", "error");
            } else {
              utils.showMessage(err.response.data, "error");
            }
            console.log(err);
          }
        },
      })
    );
  };

  const handleNewRow = () => {
    const functionId = Date.now().toString();
    apiRef.current.updateRows([
      {
        systemId: SYSTEM_ID,
        functionId: functionId,
        functionName: "",
        status: false,
        isNew: true,
      },
    ]);

    let sort = apiRef.current
      .getSortedRows()
      .sort((a, b) => a.status - b.status);
    setRowData(sort);

    apiRef.current.startRowEditMode({
      id: functionId,
      fieldToFocus: "functionName",
    });
  };

  const handleProcessRowUpdateError = (error) => {
    utils.showMessage(`Error ${error}`, "error");
  };

  const handleRowEditStop = (params) => {
    let row = params.row;
    if (params.reason === "escapeKeyDown" && row.isNew) {
      deleteRow(row.functionId);
    }
  };

  const handleAssignAction = async (row) => {
    let params = {
      systemId: SYSTEM_ID,
      functionId: row.functionId,
    };
    try {
      const response = await axios.get(
        endPoint.ActionsByFunction + utils.buildUrlParams(params)
      );
      if (response?.status === 200)
        setAssignAct((prev) => ({
          ...prev,
          functionId: row.functionId,
          functionName: row.functionName,
          data: response.data,
          open: true,
        }));
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  const processRowUpdate = async (newRow) => {
    if (newRow.functionName.trim().length < 1)
      throw new Error("Function Name Must Have Value !");

    let obj = {
      systemId: SYSTEM_ID,
      functionName: newRow.functionName,
    };

    let response;
    if (!newRow?.isNew) {
      obj.functionId = newRow.functionId;
      response = await axios.put(endPoint.FunctionInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      if (response.status === 200) {
        let rowData = response.data;
        newRow.updatedDate = rowData.updatedDate;
        newRow.updatedBy = rowData.updatedBy;
      }
      return newRow;
    } else {
      response = await axios.post(endPoint.FunctionInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      if (response.status === 200) {
        deleteRow(newRow.functionId);
        let rowData = response.data;
        newRow.functionId = rowData.functionId;
        newRow.createdDate = rowData.createdDate;
        newRow.createdBy = rowData.createdBy;
        newRow.isNew = false;
        newRow.status = true;
        return newRow;
      }
    }
  };

  const deleteRow = (functionId) => {
    apiRef.current.updateRows([{ functionId: functionId, _action: "delete" }]);
  };

  const updateRow = (functionId, updatedDate, updatedBy) => {
    apiRef.current.updateRows([
      {
        functionId: functionId,
        updatedDate: updatedDate,
        updatedBy: updatedBy,
      },
    ]);
  };

  return (
    <Box>
      <Box display="flex">
        <Restricted to={permission.create.function}>
          <Button
            variant="outlined"
            startIcon={<NoteAddOutlinedIcon />}
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            onClick={handleNewRow}
          >
            New
          </Button>
        </Restricted>
        <Button
          variant="outlined"
          startIcon={<RefreshOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.blueAccent[500],
            ":hover": {
              backgroundColor: colors.blueAccent[600],
            },
          }}
          onClick={() => fetchData()}
        >
          Refresh
        </Button>
      </Box>
      <Box
        height={"calc(100vh - 235px)"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiDataGrid-root .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiCircularProgress-root": {
            color: colors.greenAccent[400],
          },
          "& .MuiFormControl-root": {
            background: `${colors.primary[400]} !important`,
          },
        }}
      >
        <DataGrid
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          loading={loading}
          apiRef={apiRef}
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.functionId}
          editMode="row"
          autoPageSize
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          onProcessRowUpdateError={handleProcessRowUpdateError}
        />
      </Box>
      <AssignActions
        data={assignAct}
        setData={setAssignAct}
        onClose={updateRow}
      />
    </Box>
  );
};

export default FunctionGrid;
