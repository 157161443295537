import { createSlice } from "@reduxjs/toolkit";

export const activeViewportSlice = createSlice({
  name: "activeViewport",
  initialState: {
    id: "",
    element: undefined,
  },
  reducers: {
    setActiveViewport: (state, action) => {
      state.element = action.payload;
      state.id = action.payload.id;
    },
  },
});

export const { setActiveViewport } = activeViewportSlice.actions;
export default activeViewportSlice.reducer;
