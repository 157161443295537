import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Box,
  IconButton,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Carousel from "react-material-ui-carousel";

import { useTranslation } from "react-i18next";

const GuidelineDialog = ({ open, setOpen }) => {
  const { i18n } = useTranslation();

  const images = [
    {
      src: `./images/1-${i18n.language}.png`,
      alt: "guideline-1",
    },
    {
      src: `./images/2-${i18n.language}.png`,
      alt: "guideline-2",
    },
  ];

  const CarouselItem = ({ props }) => {
    return (
      <Paper>
        <img src={props.src} alt={props.alt} loading="lazy" />
      </Paper>
    );
  };

  return (
    <Dialog
      keepMounted
      onClose={() => setOpen(false)}
      open={open}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle variant="h3">Guideline</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => setOpen(false)}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent sx={{ minHeight: "80vh", overflowY: "hidden" }}>
        <Carousel autoPlay={false} navButtonsAlwaysVisible={true} height="73vh">
          {images.map((item, index) => {
            return (
              <Box key={index}>
                <CarouselItem props={item} />
              </Box>
            );
          })}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};

export default GuidelineDialog;
