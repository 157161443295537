import { IconButton } from "@mui/material";

const ToolbarButton = ({
  icon,
  btnClass,
  handleClick,
  labelClass,
  label,
  name,
  sx,
}) => {
  return (
    <IconButton
      name={name}
      className={btnClass?.length > 0 ? btnClass : "iconButton"}
      onClick={handleClick}
      sx={sx}
    >
      {icon}
      <div className={labelClass?.length > 0 ? labelClass : "iconLabel"}>
        {label}
      </div>
    </IconButton>
  );
};

export default ToolbarButton;
