import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../../theme";
import { endPoint } from "../../api/axios";
import useAxiosAdmin from "../../hooks/useAxiosAdmin";
import utils from "../../utils";
import { useEffect, useState } from "react";

import { Button, Box, TextField, useTheme } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const smtpSchema = yup.object().shape({
  smtpHost: yup.string().required("Required"),
  smtpPort: yup
    .number()
    .required("Required")
    .min(0, "Must be greater or equal 0"),
  smtpUsername: yup.string().required("Required"),
  smtpPassword: yup.string().required("Required"),
});

const SmtpSetting = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosAdmin();
  const [smtpSetting, setSmtpSetting] = useState({
    smtpHost: "",
    smtpPort: 0,
    smtpUsername: "",
    smtpPassword: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      let url = endPoint.ClientInfo + `/SmtpSetting/Client`;
      try {
        const response = await axios.get(url);
        if (response?.status === 200) setSmtpSetting(response.data);
      } catch (err) {
        console.log(err);
        utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
      }
    };
    fetchData();
  }, [axios]);

  const handleSubmitForm = async (values) => {
    let obj = {
      smtpHost: values.smtpHost,
      smtpPort: values.smtpPort,
      smtpUsername: values.smtpUsername,
      smtpPassword: values.smtpPassword,
    };
    try {
      const response = await axios.put(
        endPoint.ClientInfo + "/SmtpSetting",
        obj,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      if (response.status === 200) {
        utils.showMessage("Update Succeeded", "success");
      }
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  return (
    <Box>
      <Formik
        enableReinitialize={true}
        initialValues={smtpSetting}
        validationSchema={smtpSchema}
        onSubmit={handleSubmitForm}
        // onChange={handleOnChange}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0,1fr))"
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="SMTP Host"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpHost}
                name="smtpHost"
                error={!!errors.smtpHost}
                helperText={errors.smtpHost}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="SMTP Port"
                InputProps={{ inputProps: { min: 0 } }}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpPort}
                name="smtpPort"
                error={!!errors.smtpPort}
                helperText={errors.smtpPort}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="SMTP Username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpUsername}
                name="smtpUsername"
                error={!!errors.smtpUsername}
                helperText={errors.smtpUsername}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                autoComplete="off"
                label="SMTP Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpPassword}
                name="smtpPassword"
                error={!!errors.smtpPassword}
                helperText={errors.smtpPassword}
                sx={{ gridColumn: "span 1" }}
              />
            </Box>
            <Box display="flex" justifyContent="start" mt="20px">
              <Button
                type="submit"
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.greenAccent[600],
                  ":hover": {
                    backgroundColor: colors.greenAccent[700],
                  },
                }}
                variant="outlined"
                startIcon={<CheckOutlinedIcon />}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SmtpSetting;
