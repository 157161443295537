import cornerstone, { imageLoadPoolManager } from "cornerstone-core";

cornerstone.events.addEventListener(
  "cornerstoneimagecachefull.ImagePrefetch",
  () => cacheFullHandler()
);

export const prefetchImageIds = (imageIds, isCached) => {
  stopPrefetching();
  const nonCachedImageIds = filterCachedImageIds(imageIds);
  let requestFn;
  if (!isCached) {
    requestFn = (id) => cornerstone.loadImage(id);
  } else {
    requestFn = (id) => cornerstone.loadAndCacheImage(id);
  }

  nonCachedImageIds.forEach((imageId) => {
    imageLoadPoolManager.addRequest(requestFn.bind(this, imageId), "prefetch", {
      imageId,
    });
  });
};

const filterCachedImageIds = (imageIds) => {
  return imageIds.filter((imageId) => !isImageCached(imageId));
};

const isImageCached = (imageId) => {
  const image = cornerstone.imageCache.imageCache[imageId];
  return image && image?.sizeInBytes;
};

const cacheFullHandler = () => {
  console.log("Cache full");
  stopPrefetching();
};

const stopPrefetching = () => {
  cornerstone.imageLoadPoolManager.clearRequestStack("prefetch");
};
