import { Outlet, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useShareToken from "../hooks/useShareToken";
import useAuth from "../hooks/useAuth";
import { Backdrop, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { tokens } from "../theme";

const NotLogin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const validate = useShareToken();
  const [isLoading, setIsLoading] = useState(true);
  const { shareToken } = useAuth();
  const { studyId, token } = useParams();

  useEffect(() => {
    //TODO kiểm tra nếu để page sau đó token hết hạn
    let isMounted = true;
    const validateToken = async () => {
      try {
        await validate(studyId, token);
      } catch (err) {
        console.log(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };
    !shareToken ? validateToken() : setIsLoading(false);
    return () => (isMounted = false);
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <Backdrop
      sx={{
        color: colors.primary[100],
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Outlet />
  );
};

export default NotLogin;
