import {
  Box,
  IconButton,
  useTheme,
  Typography,
  Tooltip,
  Link,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { tokens } from "../../theme";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GuidelineDialog from "../../components/GuidelineDialog";

import { useTranslation } from "react-i18next";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import permission from "../../permission";
import Restricted from "../../components/Restricted";
import useLocalStorage from "../../hooks/useLocalStorage";

const Topbar = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  const [language, setLanguage] = useLocalStorage("language", "en");
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState({
    accMenu: null,
    langMenu: null,
  });

  const [openGuide, setOpenGuide] = useState(false);

  const openAcc = Boolean(anchorEl.accMenu);
  const openLang = Boolean(anchorEl.langMenu);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const handleClick = (e) => {
    setAnchorEl((prev) => ({
      ...prev,
      [e.currentTarget.id]: e.currentTarget,
    }));
  };

  const handleClose = (idMenu) => {
    setAnchorEl((prev) => ({
      ...prev,
      [idMenu]: null,
    }));
  };

  const handleChangeLanguage = (lang) => {
    setLanguage(lang);
    handleClose("langMenu");
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleToggleGuide = (state) => {
    if (state) setOpenGuide(state);
    else setOpenGuide(!openGuide);
  };

  return (
    <Box display="flex" justifyContent="space-between" p={1.5}>
      <Box display="flex">
        <Link component={RouterLink} to="/" underline="none">
          <Typography variant="h2" color={colors.grey[100]} fontWeight="bold">
            {t("titleHeader")}
          </Typography>
        </Link>
      </Box>
      {/* Icon */}
      <Box display="flex">
        <Tooltip title={t("home")} arrow>
          <IconButton onClick={() => navigate("/")}>
            <HomeOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" flexItem />
        {/* <Tooltip title="Color Mode" arrow>
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip> */}
        <Restricted to={permission.list.systemConfig}>
          <Tooltip title={t("systemManagement")} arrow>
            <IconButton onClick={() => navigate("/setting")}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Restricted>
        {/*Language Menu */}
        <Tooltip title={t("language")} arrow>
          <IconButton
            id="langMenu"
            onClick={handleClick}
            aria-controls={openLang ? "language-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openLang ? "true" : undefined}
            sx={{ color: colors.primary[100] }}
          >
            <Typography>{language.toUpperCase()}</Typography>
          </IconButton>
        </Tooltip>
        <Menu
          id="language-menu"
          anchorEl={anchorEl.langMenu}
          open={openLang}
          onClose={() => handleClose("langMenu")}
        >
          <MenuItem
            onClick={() => {
              handleChangeLanguage("vn");
            }}
          >
            VN
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChangeLanguage("en");
            }}
          >
            EN
          </MenuItem>
        </Menu>

        {/* Guide */}

        <Tooltip title={t("guideline")} arrow>
          <IconButton onClick={() => handleToggleGuide()}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
        <GuidelineDialog open={openGuide} setOpen={setOpenGuide} />

        {/*Account Menu */}
        <Tooltip title={t("account")} arrow>
          <IconButton
            id="accMenu"
            onClick={handleClick}
            aria-controls={openAcc ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openAcc ? "true" : undefined}
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="account-menu"
          anchorEl={anchorEl.accMenu}
          open={openAcc}
          onClose={() => handleClose("accMenu")}
        >
          <MenuItem
            onClick={() => {
              handleClose("accMenu");
              navigate("/my-account");
            }}
          >
            {t("account")}
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>{t("logout")}</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
