import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstone, { imageLoadPoolManager } from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneMath from "cornerstone-math";
import Hammer from "hammerjs";
import dicomParser from "dicom-parser";

const MAX_CONCURRENCY = 6;

const initCornerstoneDICOMImageLoader = () => {
  // const origin = window.location.origin;

  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.configure({
    useWebWorkers: true,
    decodeConfig: {
      convertFloatPixelDataToInt: false,
    },
  });

  imageLoadPoolManager.maxNumRequests = {
    interaction: 500,
    thumbnail: 500,
    prefetch: 500,
  };

  const config = {
    maxWebWorkers: Math.min(
      Math.max(navigator.hardwareConcurrency - 1, 1),
      MAX_CONCURRENCY
    ),
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        strict: false,
      },
    },
    // webWorkerTaskPaths: [
    //   `${origin}/workerTasks/610.bundle.min.worker.js`,
    //   `${origin}/workerTasks/888.bundle.min.worker.js`,
    // ],
  };

  cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
};

export const initCornerstoneTool = () => {
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.init({
    globalToolSyncEnabled: true,
    showSVGCursors: true,
  });
};

const addCustomProvider = () => {
  const { getNumberValue, getValue } =
    cornerstoneWADOImageLoader.wadors.metaData;

  const wadoRsMetaDataProvider = (type, imageId) => {
    const metaData =
      cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);

    if (!metaData) {
      return;
    }

    if (
      metaData[type] !== undefined &&
      metaData[type].Value !== undefined &&
      metaData[type].Value.length
    ) {
      return metaData[type].Value[0];
    }

    const typeCleaned = type.replace("x", "");
    if (
      metaData[typeCleaned] !== undefined &&
      metaData[typeCleaned].Value !== undefined &&
      metaData[typeCleaned].Value.length
    ) {
      return metaData[typeCleaned].Value[0];
    }

    if (type === "generalImageModule") {
      return {
        sopInstanceUid: getValue(metaData["00080018"]),
        instanceNumber: getNumberValue(metaData["00200013"]),
        lossyImageCompression: getValue(metaData["00282110"]),
        lossyImageCompressionRatio: getValue(metaData["00282112"]),
        lossyImageCompressionMethod: getValue(metaData["00282114"]),
      };
    }

    if (type === "patientModule") {
      return {
        patientName: getValue(metaData["00100010"]),
        patientId: getValue(metaData["00100020"]),
        patientSex: getValue(metaData["00100040"]),
        patientBirthDate: getValue(metaData["00100030"]),
      };
    }

    if (type === "spacingBetweenSlices") {
      return getValue(metaData["00180088"]);
    }

    if (type === "generalStudyModule") {
      return {
        studyDescription: getValue(metaData["00081030"]),
        studyDate: getValue(metaData["00080020"]),
        studyTime: getValue(metaData["00080030"]),
        accessionNumber: getValue(metaData["00080050"]),
      };
    }

    if (type === "cineModule") {
      return {
        frameTime: getNumberValue(metaData["00181063"]),
      };
    }
  };

  const wadoUriMetaDataProvider = (type, imageId) => {
    const { parseImageId, dataSetCacheManager } =
      cornerstoneWADOImageLoader.wadouri;
    const parsedImageId = parseImageId(imageId);
    const dataSet = dataSetCacheManager.get(parsedImageId.url);

    if (!dataSet) {
      return;
    }

    if (type === "generalImageModule") {
      return {
        sopInstanceUid: dataSet.string("x00080018"),
        instanceNumber: dataSet.intString("x00200013"),
        lossyImageCompression: dataSet.string("x00282110"),
        lossyImageCompressionRatio: dataSet.string("x00282112"),
        lossyImageCompressionMethod: dataSet.string("x00282114"),
      };
    }

    if (type === "patientModule") {
      return {
        patientName: dataSet.string("x00100010"),
        patientId: dataSet.string("x00100020"),
      };
    }

    if (type === "generalStudyModule") {
      return {
        studyDescription: dataSet.string("x00081030"),
        studyDate: dataSet.string("x00080020"),
        studyTime: dataSet.string("x00080030"),
      };
    }

    if (type === "cineModule") {
      return {
        frameTime: dataSet.floatString("x00181063"),
      };
    }

    if (dataSet.elements[type] !== undefined) {
      const element = dataSet.elements[type];
      if (!element.vr) {
        return;
      }

      return dicomParser.explicitElementToString(dataSet, element);
    }
  };

  cornerstone.metaData.addProvider(wadoRsMetaDataProvider);
  cornerstone.metaData.addProvider(wadoUriMetaDataProvider);
};

const initCornerstone = async () => {
  window.cornerstone = cornerstone; //TODO Delete
  window.cornerstoneTools = cornerstoneTools;
  initCornerstoneDICOMImageLoader();
  addCustomProvider();
};

export default initCornerstone;
