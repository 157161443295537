import { Box, useTheme, CircularProgress, Backdrop } from "@mui/material";
import ViewportOverlay from "./ViewportOverlay";

import { tokens } from "../../../theme";
import cornerstone, { loadAndCacheImage } from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstoneTools from "cornerstone-tools";
import { useCallback, useEffect, useState } from "react";
import cornerstoneService from "../../../services/cornerstoneService";
import { prefetchImageIds } from "../../../services/prefetchImageService";
import useAxiosPacs from "../../../hooks/useAxiosPacs";
import { tags } from "../../../services/constant";
import UrlSingleton from "../../../context/UrlSingleton";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setActiveViewport } from "../../../redux/activeViewport";
import { setIsFetchImage } from "../../../redux/seriesList";

const DicomViewport = ({ studyId, index }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosPacs();
  const dispatch = useDispatch();
  const { urlPacs } = new UrlSingleton();
  const activeViewport = useSelector((state) => state.activeViewport);
  const { activeSeries, isFetchImage } = useSelector(
    (state) => state.seriesList
  );
  const [loading, setLoading] = useState(false);
  const [overlayData, setOverlayData] = useState({
    imageIds: [],
    imageIdIndex: 0,
    imageId: "",
    scale: 0,
    windowCenter: 0,
    windowWidth: 0,
    rotationDegrees: 0,
    isFlippedVertically: false,
    isFlippedHorizontally: false,
  });

  const viewportId = cornerstoneService.getViewportId(index);
  const isActiveViewport =
    activeViewport.id === cornerstoneService.getViewportId(index);

  const bindElementEventListeners = useCallback(
    (clear = false) => {
      const onNewImage = (event) => {
        const { imageId } = event.detail.image;
        const currentImageIdIndex = overlayData.imageIds
          ? overlayData.imageIds.indexOf(imageId)
          : 0;
        setOverlayData((prev) => ({
          ...prev,
          imageIdIndex: currentImageIdIndex,
          imageId: imageId,
        }));
      };

      const onImageRendered = (event) => {
        const viewport = event.detail.viewport;

        setOverlayData((prev) => ({
          ...prev,
          scale: viewport.scale,
          windowCenter: viewport.voi.windowCenter,
          windowWidth: viewport.voi.windowWidth,
          rotationDegrees: viewport.rotation,
          isFlippedVertically: viewport.vflip,
          isFlippedHorizontally: viewport.hflip,
        }));
      };

      const element = document.getElementById(viewportId);

      const addOrRemoveEventListener = clear
        ? "removeEventListener"
        : "addEventListener";

      // Updates state's imageId, and imageIndex
      element[addOrRemoveEventListener](
        cornerstone.EVENTS.NEW_IMAGE,
        onNewImage
      );

      element[addOrRemoveEventListener](
        cornerstone.EVENTS.IMAGE_RENDERED,
        onImageRendered
      );
    },
    [overlayData.imageIds, viewportId]
  );

  const fetchImage = useCallback(async () => {
    setLoading(true);
    try {
      let seriesId = activeSeries;
      let activeElement = activeViewport.element;
      let urlSeries = `/dicom-web/studies/${studyId}/series/${seriesId}/metadata`;
      const res = await axios.get(urlSeries);
      let imageIds = [];
      let sortInstance = res.data.sort((a, b) => {
        return (
          cornerstoneService.getTag(a, tags.InstanceNumber) -
          cornerstoneService.getTag(b, tags.InstanceNumber)
        );
      });
      for (var i = 0; i < sortInstance.length; i++) {
        let metaData = sortInstance[i];
        let imageId = cornerstoneService.wadoUriBuilder(
          urlPacs,
          studyId,
          seriesId,
          metaData["00080018"].Value
        );

        cornerstoneWADOImageLoader.wadors.metaDataManager.add(
          imageId,
          metaData
        );

        imageIds.push(imageId);
      }

      setOverlayData({
        imageIds: imageIds,
        imageIdIndex: 0,
        imamgeId: imageIds[0],
      });

      const stack = {
        currentImageIdIndex: 0,
        imageIds: imageIds,
      };

      loadAndCacheImage(imageIds[0]).then((image) => {
        const viewport = cornerstone.getDefaultViewportForImage(
          activeElement,
          image
        );
        cornerstone.displayImage(activeElement, image, viewport);
        cornerstoneTools.addStackStateManager(activeElement, ["stack"]);
        cornerstoneTools.addToolState(activeElement, "stack", stack);
      });
      prefetchImageIds(imageIds);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [activeSeries, activeViewport.element, axios, urlPacs, studyId]);

  useEffect(() => {
    const initViewport = () => {
      cornerstoneService.enableViewport(viewportId);
    };
    initViewport();
    bindElementEventListeners();
  }, [bindElementEventListeners, viewportId]);

  useEffect(() => {
    if (isActiveViewport && isFetchImage) {
      fetchImage();
      dispatch(setIsFetchImage(false));
    }
  }, [fetchImage, dispatch, isActiveViewport, isFetchImage]);

  const handleSelectViewport = (index) => {
    const element = document.getElementById(viewportId);
    dispatch(setActiveViewport(element));
  };

  return (
    <Box
      sx={isActiveViewport ? { border: `2px solid ${colors.grey[100]}` } : {}}
      className="grid-item cornerstone-enabled-image dicom-viewer-outside"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => handleSelectViewport(index)}
    >
      <div className="dicom-viewer" id={viewportId}></div>
      <ViewportOverlay
        imageIndex={overlayData.imageIdIndex + 1}
        stackSize={overlayData.imageIds.length}
        scale={overlayData.scale}
        windowWidth={overlayData.windowWidth}
        windowCenter={overlayData.windowCenter}
        imageId={overlayData.imageId}
      />
      <Backdrop
        sx={{
          color: colors.blueAccent[400],
          position: "absolute",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default DicomViewport;
