import { Routes, Route } from "react-router-dom";
import LoginLayout from "./components/LoginLayout";
import Layout from "./components/Layout";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";
import NotRequireAuth from "./components/NotRequireAuth";
import NotLogin from "./components/NotLogin";

import Viewer from "./pages/viewer";
import Studies from "./pages/studies";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import Forbidden from "./pages/forbidden";
import NotFound from "./pages/notFound";
import SystemSetting from "./pages/setting";
import ResetPassword from "./pages/resetPassword";
import MyAccount from "./pages/account";

function App() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Route>
      <Route path="/" element={<Layout />}>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth permission="" />}>
            <Route path="/" element={<Studies />} />
            <Route path="/study/:studyId" element={<Viewer />} />
            <Route path="/setting" element={<SystemSetting />} />
          </Route>
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="forbidden" element={<Forbidden />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route element={<NotLogin />}>
          <Route element={<NotRequireAuth />}>
            <Route path="/share/:studyId/:token" element={<Viewer />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
