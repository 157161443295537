import { useEffect } from "react";
import { useParams } from "react-router";
import initCornerStone from "../../init/initCornerstone";
import { useDispatch, useSelector } from "react-redux";
import { setActiveViewport } from "../../redux/activeViewport";
import { resizeViewports } from "../../redux/viewportGrid";
import cornerstoneService from "../../services/cornerstoneService";
import { addViewport } from "../../redux/viewportGrid";
// import useAuth from "../../hooks/useAuth";
// import imageLoaderXhrConfig from "../../init/imageLoaderXhrConfig";
// import useRefreshToken from "../../hooks/useRefreshToken";
//UI
import { Box } from "@mui/material";
import SeriesList from "./components/SeriesList";
import ToolBar from "./components/ToolBar";
import DicomViewport from "./components/DicomViewport";
import Diagnostic from "./components/Diagnostic";

const Viewer = () => {
  const { studyId } = useParams();
  // const { auth, shareToken } = useAuth();
  const viewportGrid = useSelector((state) => state.viewportGrid);
  const dispatch = useDispatch();
  const rowSize = 100 / viewportGrid.rows;
  const colSize = 100 / viewportGrid.columns;
  const numberLayout = viewportGrid.rows * viewportGrid.columns;
  // const refresh = useRefreshToken();

  // useEffect(() => {
  //   if (auth?.token) {
  //     imageLoaderXhrConfig(auth?.token);
  //   } else imageLoaderXhrConfig(shareToken);
  // }, [auth?.token, shareToken]);

  useEffect(() => {
    const init = async () => {
      await initCornerStone();
    };

    const enableDefaultViewport = () => {
      let viewportId = cornerstoneService.getViewportId(0);
      let element = document.getElementById(viewportId);
      cornerstoneService.enableViewport(element);
      dispatch(setActiveViewport(element));
    };

    init();
    enableDefaultViewport();
  }, [dispatch]);

  useEffect(() => {
    if (viewportGrid.isResizeGrid) {
      dispatch(resizeViewports());
    }
  }, [dispatch, viewportGrid.isResizeGrid]);

  useEffect(() => {
    const addViewports = () => {
      for (let index = 0; index < numberLayout; index++) {
        let viewportId = cornerstoneService.getViewportId(index);
        let element = document.getElementById(viewportId);
        let viewport = {
          id: viewportId,
          element: element,
        };
        dispatch(addViewport(viewport));
      }
    };
    addViewports();
  }, [dispatch, numberLayout]);

  return (
    <Box>
      <SeriesList studyId={studyId} />
      <Diagnostic />
      <Box display="flex" ml="1em">
        <ToolBar studyId={studyId} />
      </Box>
      <Box height={"calc(100vh - 125px)"}>
        <Box
          component="div"
          className="grid-container"
          style={{
            gridTemplateColumns: `repeat(${viewportGrid.columns},${colSize}%)`,
            gridTemplateRows: `repeat(${viewportGrid.rows}, ${rowSize}%)`,
          }}
        >
          {(() => {
            let box = [];
            for (let index = 0; index < numberLayout; index++) {
              box.push(
                <Box key={index}>
                  <DicomViewport index={index} studyId={studyId} />
                </Box>
              );
            }
            return box;
          })()}
        </Box>
      </Box>
    </Box>
  );
};

export default Viewer;
