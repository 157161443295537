const listAct = {
  action: "ListAction",
  function: "ListFunction",
  role: "ListRolesByClient",
  account: "ListAccountClients",
  study: "ViewAllStudy",
  systemConfig: "SystemConfig",
  diagnosticTemplate: "ListDiagnosticTemplate",
};

const createAct = {
  function: "CreateFunction",
  actionsFunction: "CreateActionFunction",
  role: "CreateRoleByClient",
  functionRole: "CreateFunctionsRole",
  account: "CreateAccount",
  roleAccount: "AssignRoleAccount",
  shareLink: "ShareLinkStudy",
  diagnostic: "DiagnosticConclusion",
  diagnosticTemplate: "CreateDiagnosticTemplate",
};

const updateAct = {
  function: "UpdateFunction",
  role: "UpdateRoleByClient",
  defaultRole: "SetDefaultRole",
  account: "UpdateAccount",
  resetPwd: "ResetPassword",
  smtp: "SmtpSetting",
  diagnostic: "DiagnosticConclusion",
  diagnosticTemplate: "UpdateDiagnosticTemplate",
};

const deleteAct = {
  function: "DeleteFunction",
  role: "DeleteRoleByClient",
  account: "DeleteAccount",
  diagnostic: "DiagnosticConclusion",
  diagnosticTemplate: "DeleteDiagnosticTemplate",
};

const permission = {
  list: listAct,
  create: createAct,
  update: updateAct,
  delete: deleteAct,
};

export default permission;
