import useAuth from "./useAuth";
import { axiosAuth, endPoint, SYSTEM_ID } from "../api/axios";
import { useNavigate } from "react-router-dom";
import UrlSingletone from "../context/UrlSingleton";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const refresh = async () => {
    try {
      let url = endPoint.Auth + "/Refresh";
      const response = await axiosAuth.post(url, SYSTEM_ID);
      let data = response.data;
      setAuth((prev) => ({
        ...prev,
        username: data.username,
        fullName: data.fullName,
        actions: data.actionsControl,
        token: data.token,
        // urlPacs: data.urlPacs,
        // urlPacsApi: data.urlPacsApi,
      }));

      new UrlSingletone(data.urlPacs, data.urlPacsApi);

      return data.token;
    } catch (error) {
      if (error?.response.status === 401) navigate("/login", { replace: true });
    }
  };

  return refresh;
};

export default useRefreshToken;
