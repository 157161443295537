import { useSelector } from "react-redux";
import useAxiosPacs from "../../../hooks/useAxiosPacs";
import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setActiveSeries } from "../../../redux/seriesList";
import useAuth from "../../../hooks/useAuth";
import imageLoaderXhrConfig from "../../../init/imageLoaderXhrConfig";
import useRefreshToken from "../../../hooks/useRefreshToken";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Drawer,
  Box,
  Grid,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import "./css/SeriesList.css";

const SeriesList = ({ studyId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const sideBar = useSelector((state) => state.seriesList);
  const axios = useAxiosPacs();
  const dispatch = useDispatch();
  const { auth, shareToken } = useAuth();
  const refresh = useRefreshToken();
  const navigate = useNavigate();

  const [series, setSeries] = useState([]);
  const [thumbnails, setThumbnails] = useState({});

  const fetchListSeries = useCallback(async () => {
    const controller = new AbortController();
    try {
      let payload = {
        Level: "Series",
        Query: {
          StudyInstanceUID: studyId,
        },
        Expand: true,
      };
      let arr = [];
      const res = await axios.post(`/tools/find`, JSON.stringify(payload), {
        signal: controller.signal,
      });

      for (var i = 0; i < res.data.length; i++) {
        let data = res.data[i];
        let imageUrl = `/instances/${data.Instances[0]}/rendered`;
        arr.push({
          seriesNumber: data.MainDicomTags.SeriesNumber,
          seriesDescription: data.MainDicomTags.SeriesDescription,
          seriesInstanceUID: data.MainDicomTags.SeriesInstanceUID,
          numberOfImage: data.Instances.length,
          imageUrl: imageUrl,
        });
      }
      setSeries(arr);
    } catch (err) {
      console.log(err);
    }
    return () => {
      controller.abort();
      // revokeThumbnailUrl();
    };
  }, [studyId, axios]);

  useEffect(() => {
    fetchListSeries();
  }, [fetchListSeries]);

  useEffect(() => {
    const createThumbnailUrl = async () => {
      for (var i = 0; i < series.length; i++) {
        let item = series[i];
        let existUrl = thumbnails[item.seriesInstanceUID];
        if (existUrl) {
          continue;
        } else {
          const res = await axios.get(item.imageUrl, {
            responseType: "arraybuffer",
          });
          const blob = new Blob([res.data], {
            type: "image/jpeg",
          });
          const objectUrl = URL.createObjectURL(blob);
          setThumbnails((prev) => ({
            ...prev,
            [item.seriesInstanceUID]: objectUrl,
          }));
        }
      }
    };
    createThumbnailUrl();
    // eslint-disable-next-line
  }, [series, axios]);

  const onSeriesClick = async (e) => {
    if (auth?.token) {
      const newToken = await refresh(); // refresh token before image load
      imageLoaderXhrConfig(newToken, tokenErrorCallback);
    } else imageLoaderXhrConfig(shareToken, tokenErrorCallback);

    let seriesId = e.target.id;
    dispatch(
      setActiveSeries({
        id: seriesId,
        isFetchImage: true,
      })
    );
  };

  const tokenErrorCallback = () => {
    navigate("/login", { replace: true });
  };

  // const revokeThumbnailUrl = () => {
  //   Object.keys(thumbnails).forEach(function (key, index) {
  //     URL.revokeObjectURL(thumbnails[key]);
  //   });
  //   setThumbnails({});
  // };

  return (
    <Drawer
      anchor="left"
      open={sideBar.isOpen}
      variant="persistent"
      hideBackdrop
      sx={{
        "& .MuiPaper-root.MuiPaper-elevation": {
          marginTop: "115px",
          height: "calc(100vh - 18vh)",
          zIndex: 800,
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box className="series-list" role="presentation">
        <Card
          variant="outlined"
          sx={{ backgroundColor: colors.primary[400], overflowY: "auto" }}
        >
          {series.map((item) => {
            return (
              <CardContent
                key={item.seriesInstanceUID}
                sx={{
                  "& .active-unit": {
                    border: `2px solid ${colors.grey[100]}`,
                  },
                }}
              >
                <Box
                  className={
                    sideBar.activeSeries === item.seriesInstanceUID
                      ? "active-unit"
                      : ""
                  }
                >
                  <CardMedia
                    id={item.seriesInstanceUID}
                    component="img"
                    className="series-img"
                    onClick={(e) => onSeriesClick(e)}
                    alt=""
                    src={thumbnails[item.seriesInstanceUID]}
                    sx={{ cursor: "pointer" }}
                  />
                  <Typography color={colors.grey[100]}>
                    {item.seriesDescription}
                  </Typography>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography color={colors.grey[100]}>
                        S: {item.seriesNumber}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography color={colors.grey[100]}>
                        I : {item.numberOfImage}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            );
          })}
        </Card>
      </Box>
    </Drawer>
  );
};

export default SeriesList;
