import { tokens } from "../../theme";
import { endPoint } from "../../api/axios";
import { Formik } from "formik";
import * as yup from "yup";
import useAxiosAdmin from "../../hooks/useAxiosAdmin";
import utils from "../../utils";

import { Box, Button, TextField, useTheme } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required("Required"),
  newPassword: yup
    .string()
    .matches(
      passRegex,
      "Password must be 8 to 24 characters long, have at least one lower case, one upper case, one digit and one special character (!@#$%)"
    )
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      "New Password and Confirm Password must match"
    ),
});

const ChangePassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosAdmin();

  const handleChangePassword = async (values) => {
    let obj = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    try {
      const response = await axios.put(
        endPoint.AccountInfo + "/ChangePassword",
        obj,
        {
          headers: {
            "Content-Type": "application/json;",
          },
        }
      );
      if (response.status === 200) {
        utils.showMessage("Update Succeeded", "success");
      }
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  return (
    <Formik
      onSubmit={handleChangePassword}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={passwordSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(3, minmax(0,1fr))"
          >
            <TextField
              fullWidth
              autoComplete="off"
              variant="filled"
              type="password"
              label="Current Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.currentPassword}
              name="currentPassword"
              error={!!errors.currentPassword}
              helperText={errors.currentPassword}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              autoComplete="off"
              variant="filled"
              type="password"
              label="New Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.newPassword}
              name="newPassword"
              error={!!errors.newPassword}
              helperText={errors.newPassword}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              fullWidth
              autoComplete="off"
              variant="filled"
              type="password"
              label="Confirm Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.confirmPassword}
              name="confirmPassword"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              sx={{ gridColumn: "span 1" }}
            />
          </Box>
          <Box display="flex" justifyContent="start" mt="20px">
            <Button
              type="submit"
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.greenAccent[600],
                ":hover": {
                  backgroundColor: colors.greenAccent[700],
                },
              }}
              variant="outlined"
              startIcon={<CheckOutlinedIcon />}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ChangePassword;
