import axios from "axios";

const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;
const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const PACS_URL = process.env.REACT_APP_PACS_URL;

export const SYSTEM_ID = process.env.REACT_APP_SYSTEM;

export const axiosAdmin = axios.create({
  baseURL: ADMIN_API_URL,
});

export const axiosPacs = axios.create();

export const axiosAuth = axios.create({
  baseURL: AUTH_API_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export const axiosApiPacs = axios.create();

export const endPoint = {
  Auth: "/Authenticate",
  AccountInfo: "/AccountInfo",
  ActionInfo: "/ActionInfo",
  ActionsByFunction: "/ActionsByFunction",
  ClientInfo: "/ClientInfo",
  DiagnosticInfo: "/Diagnostic",
  DiagosticResultTemplate: "/DiagnosticResultTemplate",
  FunctionInfo: "/FunctionInfo",
  FunctionsByRole: "/FunctionsByRole",
  RolesByClient: "/RolesByClient",
  ShareLink: "/ShareLink",
  Studies: "/Studies",
};
