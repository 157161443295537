import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { tokens } from "../../theme";

import { axiosAuth, endPoint, SYSTEM_ID } from "../../api/axios";
import { useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import utils from "../../utils";
import useAuth from "../../hooks/useAuth";
import useToggle from "../../hooks/useToggle";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Formik } from "formik";
import * as yup from "yup";
import UrlSingleton from "../../context/UrlSingleton";

const schema = yup.object().shape({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setAuth } = useAuth();
  const [check, toggleCheck] = useToggle("persist", false);

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 300,
    margin: "auto",
  };

  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";

  const [user, setUser] = useLocalStorage("user", "");
  const userRef = useRef();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmitForm = async (values) => {
    try {
      let user = {
        SystemId: SYSTEM_ID,
        Username: values.username,
        Password: values.password,
      };
      const response = await axiosAuth.post(endPoint.Auth, user);
      let data = response?.data;
      let fullName = data?.fullName;
      let token = data?.token;
      let actions = data?.actionsControl;
      let username = data?.username;

      window.test = new UrlSingleton(data.urlPacs, data.urlPacsApi);

      setAuth({ username, fullName, token, actions });
      setUser("");
      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else if (err.response?.status === 400) {
        utils.showMessage("Missing Username or Password", "error");
      } else if (err.response?.status === 401 || err.response?.status === 403) {
        utils.showMessage(err.response.data, "error");
      } else {
        utils.showMessage("Login Failed", "error");
      }
      console.log(err);
    }
  };

  return (
    <Paper elevation={10} style={paperStyle}>
      <Grid align="center">
        <Avatar style={{ backgroundColor: colors.greenAccent[500] }}>
          <LockOutlinedIcon />
        </Avatar>
        <h2>Sign In</h2>
      </Grid>
      <Formik
        initialValues={{
          username: user,
          password: "",
        }}
        validationSchema={schema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box className="login-textbox" paddingTop={1}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                placeholder="Enter username"
                inputRef={userRef}
                onChange={(e) => {
                  setUser(e.currentTarget.value);
                  handleChange(e);
                }}
                defaultValue={values.username}
                error={!!errors.username}
                helperText={errors.username}
              />
            </Box>
            <Box className="login-textbox" paddingTop={1}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                placeholder="Enter password"
                type="password"
                autoComplete="off"
                defaultValue={values.password}
                error={!!errors.password}
                helperText={errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Box>
            <FormControlLabel
              control={<Checkbox name="checkedB" color="primary" />}
              label="Remember me"
              onChange={toggleCheck}
              checked={check}
            />
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Sign in
            </Button>
          </form>
        )}
      </Formik>
      <Box paddingTop={1}>
        <Typography>
          <Link to="/forgot-password">Forgot your password ?</Link>
        </Typography>
      </Box>
    </Paper>
  );
};

export default Login;
