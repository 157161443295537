import { useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftConvertService from "../../../services/draftConvertService";
import DOMPurify from "dompurify";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { tokens } from "../../../theme";
import { Box, useTheme } from "@mui/material";

const TemplateBox = ({
  data,
  defaultEdit,
  toolbar,
  submitMethod,
  callBackSubmit,
  onCancel,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isEdit = defaultEdit;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      draftConvertService.contentFromHtml(data?.templateContent)
    )
  );

  const editorStyle = {
    backgroundColor: colors.primary[400],
    color: colors.grey[100],
    border: `1px solid ${colors.primary[100]}`,
    minHeight: "20vh",
  };

  const toolbarStyle = {
    backgroundColor: colors.primary[400],
    color: colors.grey[500],
    border: `1px solid ${colors.primary[100]}`,
  };

  const createMarkup = (html) => {
    if (html)
      return {
        __html: DOMPurify.sanitize(html),
      };

    return {
      __html: "",
    };
  };

  submitMethod.updateTemplate = () => {
    let content = editorState.getCurrentContent();
    if (!content || content.size < 1) return;
    let htmlContent = DOMPurify.sanitize(
      draftConvertService.contentToHtml(content)
    );
    let obj = {
      id: data.templateId,
      content: htmlContent,
    };
    callBackSubmit(obj);
  };

  return (
    <>
      {isEdit ? (
        <Box
          sx={{
            padding: "5px",
            border: `1px solid ${colors.primary[100]}`,
          }}
        >
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorStyle={editorStyle}
            toolbarStyle={toolbarStyle}
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
            toolbar={toolbar}
          />
        </Box>
      ) : (
        <Box p={1} sx={{ border: `1px solid ${colors.primary[100]}` }}>
          <Box
            variant="div"
            dangerouslySetInnerHTML={createMarkup(data?.templateContent)}
          />
        </Box>
      )}
    </>
  );
};

export default TemplateBox;
