import { useEffect, useState, useCallback } from "react";
import useAxiosApiPacs from "../../../hooks/useAxiosApiPacs";
import { tokens } from "../../../theme";
import { endPoint } from "../../../api/axios";

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
} from "@mui/material";

const TemplateDropdown = ({ callBack }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectData, setSelectData] = useState([]);
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const axios = useAxiosApiPacs();

  const fetchData = useCallback(async () => {
    const controller = new AbortController();
    try {
      const response = await axios.get(endPoint.DiagosticResultTemplate, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setSelectData(response.data);
    } catch (err) {
      console.log(err);
    }
    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (e) => {
    setOpen(false);
    setSelected(e.target.value);
    callBack(e.target.value);
  };

  return (
    <Box
      sx={{
        minWidth: 120,
        "& label.Mui-focused": {
          color: `${colors.greenAccent[100]}`,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: `${colors.greenAccent[100]}`,
          },
          "&:hover fieldset": {
            borderColor: `${colors.greenAccent[200]}`,
          },
          "&.Mui-focused fieldset": {
            borderColor: `${colors.greenAccent[200]}`,
          },
        },
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel id="template-select">Template</InputLabel>
        <Select
          labelId="template-select"
          id="simple-select-id"
          value={selected}
          label="Template"
          onChange={handleChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          {selectData.map((obj, index) => {
            return (
              <MenuItem key={obj.templateId} value={obj.templateContent}>
                {obj.templateName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TemplateDropdown;
