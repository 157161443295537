import { tokens } from "../../theme";
import { useEffect, useState, useCallback } from "react";
import { endPoint } from "../../api/axios";
import { Formik } from "formik";
import format from "date-fns/format";
import * as yup from "yup";
import useAxiosAdmin from "../../hooks/useAxiosAdmin";
import utils from "../../utils";

import { Box, Button, TextField, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const phoneRegex =
  /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/; // Regex ĐT VN

// const phoneRegex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

const profileSchema = yup.object().shape({
  fullName: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegex, "Invalid Phone Number")
    .notRequired(),
  birthdate: yup.date().notRequired(),
});

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axios = useAxiosAdmin();

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    birthdate: "",
  });

  const fetchData = useCallback(async () => {
    const controller = new AbortController();

    try {
      let url = endPoint.AccountInfo + "/Profile";
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json;",
        },
        signal: controller.signal,
      });
      setProfile(response?.data);
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditProfile = async (values) => {
    let obj = {
      fullName: values.fullName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      birthdate: values.birthdate
        ? format(new Date(values.birthdate), "dd/MM/yyyy")
        : "",
    };
    try {
      const response = await axios.put(endPoint.AccountInfo + "/Profile", obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      if (response.status === 200) {
        utils.showMessage("Update Succeeded", "success");
      }
    } catch (err) {
      console.log(err);
      utils.showMessage(`${err.message} : ${err.response?.data}`, "error");
    }
  };

  return (
    <Box>
      {loading || profile.fullName.length < 1 ? (
        <CircularProgress />
      ) : (
        <Formik
          enableReinitialize
          onSubmit={handleEditProfile}
          initialValues={profile}
          validationSchema={profileSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Full Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName ?? ""}
                  name="fullName"
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email ?? ""}
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber ?? ""}
                  name="phoneNumber"
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Date of Birth"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={
                    values.birthdate
                      ? format(new Date(values.birthdate), "yyyy-MM-dd")
                      : ""
                  }
                  name="birthdate"
                  error={!!errors.birthdate}
                  helperText={errors.birthdate}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              <Box display="flex" justifyContent="start" mt="20px">
                <Button
                  type="submit"
                  sx={{
                    color: colors.grey[100],
                    backgroundColor: colors.greenAccent[600],
                    ":hover": {
                      backgroundColor: colors.greenAccent[700],
                    },
                  }}
                  variant="outlined"
                  startIcon={<CheckOutlinedIcon />}
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default Profile;
