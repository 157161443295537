import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import utils from "../utils";

const RequireAuth = ({ permission }) => {
  const { auth } = useAuth();
  const location = useLocation();
  let allowed = utils.checkContains(auth?.actions, permission);

  return auth?.token ? (
    allowed ? (
      <Outlet />
    ) : (
      <Navigate to="/forbidden" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
