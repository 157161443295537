import cornerstone from "cornerstone-core";

const qidoUriBuilder = (level, filter, limit, offset, fieldInclude) => {
  let filterString = "";
  let fields;
  if (typeof filter !== "undefined") {
    let objKeys = Object.keys(filter);
    objKeys.forEach((key) => {
      if (filter[key].length > 0) {
        filterString += `&${key}=${filter[key]}`;
      }
    });
  }
  if (typeof fieldInclude !== "undefined" && fieldInclude.length > 0) {
    fields = `&includefield=${fieldInclude.toString()}`;
  }
  return `/dicom-web/${level}?limit=${limit}&offset=${offset}${fields}${filterString}`;
};

const wadoUriBuilder = (urlPacs, studyId, seriesId, instanceId) => {
  return `wadouri:${urlPacs}/wado?requestType=WADO&studyUID=${studyId}&seriesUID=${seriesId}&objectUID=${instanceId}&contentType=application%2Fdicom`;
};

const wadoRsUriBuilder = (urlPacs, studyId, seriesId, instanceId) => {
  return `wadors:${urlPacs}/dicom-web/studies/${studyId}/series/${seriesId}/instances/${instanceId}/frames/1`;
};

const getTag = (input, tag) => {
  let element = input[tag];
  if (!(tag in input) || !("Value" in element) || element.length === 0) {
    return "";
  }
  if (element.Value[0].Alphabetic) {
    return element.Value[0].Alphabetic;
  }
  return element["Value"][0];
};

const getViewportId = (index) => {
  return `viewport-${index}`;
};

const enableViewport = (param) => {
  var element;
  if (param instanceof HTMLElement) element = param;
  else element = document.getElementById(param);
  let enabledElements = cornerstone.getEnabledElements();
  let enabled = enabledElements.some((item) => item.element === element);
  if (!enabled) {
    cornerstone.enable(element);
  }
};

const cornerstoneService = {
  qidoUriBuilder,
  wadoUriBuilder,
  wadoRsUriBuilder,
  getTag,
  getViewportId,
  enableViewport,
};

export default cornerstoneService;
