import { useSelector } from "react-redux";
import useAxiosApiPacs from "../../../hooks/useAxiosApiPacs";
import { useEffect, useState, useCallback } from "react";
import Restricted from "../../../components/Restricted";
import permission from "../../../permission";
import utils from "../../../utils";
import { endPoint } from "../../../api/axios";
// import { useDispatch } from "react-redux";
// import { openDialog } from "../../../redux/confirm";

import { Drawer, Box, Button, useTheme, Grid } from "@mui/material";
import { tokens } from "../../../theme";
import RichTextEditor from "./RichTextEditor";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import CircularProgress from "@mui/material/CircularProgress";

const Diagnostic = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const diagnostic = useSelector((state) => state.diagnostic);
  const { activeSeries } = useSelector((state) => state.seriesList);
  // const dispatch = useDispatch();
  const axios = useAxiosApiPacs();
  const [loading, setLoading] = useState(true);
  const [diagnosticResult, setDiagnosticResult] = useState([]);
  const [showAdd, setShowAdd] = useState(false);

  const toolbar = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "colorPicker",
      "history",
    ],
    inline: {
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "superscript",
        "subscript",
      ],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
    fontsize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60],
    },
    textAlign: {
      options: ["left", "center", "right"],
    },
  };

  const fetchData = useCallback(async () => {
    const controller = new AbortController();
    if (activeSeries) {
      setLoading(true);
      try {
        let url = endPoint.DiagnosticInfo + `/${activeSeries}`;
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json;",
          },
        });
        setDiagnosticResult(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    return () => {
      controller.abort();
    };
  }, [activeSeries, axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleAdd = () => {
    setShowAdd(true);
  };

  const handleNewResult = async (data) => {
    try {
      let obj = {
        content: data.content,
        seriesId: activeSeries,
      };
      const response = await axios.post(endPoint.DiagnosticInfo, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      let entity = response.data;
      obj.resultId = entity.resultId;
      obj.createdDate = entity.createdDate;
      obj.createdBy = entity.createdBy;
      setDiagnosticResult((current) => [...current, obj]);
      setShowAdd(false);
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={diagnostic.isOpen}
      variant="persistent"
      hideBackdrop
      sx={{
        "& .MuiPaper-root.MuiPaper-elevation": {
          marginTop: "115px",
          height: "calc(100vh - 18vh)",
          zIndex: 800,
          backgroundColor: colors.primary[400],
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box sx={{ width: "50vw" }} role="presentation">
        {activeSeries ? (
          <Box>
            {loading ? (
              <Grid align="center" p={1}>
                <CircularProgress color="inherit" />
              </Grid>
            ) : (
              diagnosticResult?.map((item, index) => {
                return (
                  <Box key={index}>
                    <RichTextEditor
                      data={item}
                      defaultEdit={false}
                      // onSave={handleUpdateResult}
                      // onDelete={handleDeleteResult}
                      toolbar={toolbar}
                    />
                  </Box>
                );
              })
            )}
            <Restricted to={permission.create.diagnostic}>
              {!showAdd ? (
                <Button
                  variant="outlined"
                  startIcon={<NoteAddOutlinedIcon />}
                  sx={{
                    margin: "5px",
                    color: colors.grey[100],
                    backgroundColor: colors.greenAccent[600],
                    ":hover": {
                      backgroundColor: colors.greenAccent[700],
                    },
                  }}
                  onClick={toggleAdd}
                >
                  New
                </Button>
              ) : (
                <RichTextEditor
                  data={{
                    content: "",
                    resultId: Date.now().toString(),
                    createdDate: "",
                  }}
                  defaultEdit={true}
                  onSave={handleNewResult}
                  onCancel={() => setShowAdd(false)}
                  toolbar={toolbar}
                />
              )}
            </Restricted>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Drawer>
  );
};

export default Diagnostic;
