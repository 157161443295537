import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  useTheme,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { tokens } from "../../../theme";
import { useState } from "react";
import { endPoint } from "../../../api/axios";
import useAxiosAdmin from "../../../hooks/useAxiosAdmin";
import utils from "../../../utils";

const AssignActions = ({ data, setData, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["actionName", "actionControl"]);
  const axios = useAxiosAdmin();

  const handleSubmit = async () => {
    let actionIds = data.data
      .filter((value) => {
        return value.checked;
      })
      .map((value) => {
        return value.actionId;
      });
    let obj = {
      functionId: data.functionId,
      actionId: actionIds,
    };
    try {
      const response = await axios.post(endPoint.ActionsByFunction, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      if (response.status === 200) {
        utils.showMessage("Assign Actions Succeeded", "success");
        onClose(
          data.functionId,
          response.data.updatedDate,
          response.data.updatedBy
        );
        closeDialog();
      }
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  const closeDialog = () => {
    setData((prev) => ({
      ...prev,
      open: false,
      data: [],
    }));
    setQuery("");
  };

  const handleCheck = (e) => {
    let checked = e.target.checked;
    let actionId = e.target.value;
    let newArr = [];
    newArr = data.data.map((obj) => {
      if (obj.actionId === actionId) return { ...obj, checked: checked };
      return obj;
    });
    setData((prev) => ({
      ...prev,
      data: newArr,
    }));
  };

  return (
    <Dialog open={data.open} maxWidth="lg" fullWidth>
      <DialogTitle variant="h3">
        Assign Actions For Function: "{data.functionName}"
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => closeDialog()}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          "& .MuiCheckbox-root.Mui-checked": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box
          p={1}
          sx={{
            "& label.Mui-focused": {
              color: `${colors.primary[100]}`,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: `${colors.primary[100]}`,
              },
              "&:hover fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
              "&.Mui-focused fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
            },
          }}
        >
          <TextField
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Box>
        <Box padding={1}>
          <Card
            sx={{
              border: `1px solid ${colors.primary[100]}`,
            }}
          >
            <CardHeader
              title="Actions"
              sx={{ background: colors.blueAccent[500] }}
            />
            <CardContent>
              <Grid container spacing={1}>
                {utils
                  .search(data.data, searchParam, query)
                  .map((value, index) => {
                    return (
                      <Grid item xs={4} sm={4} md={4} key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={value.actionId}
                              checked={value.checked}
                              onChange={handleCheck}
                              value={value.actionId}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label={`${value.actionName}`}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit()}
          startIcon={<CheckOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.greenAccent[600],
            ":hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
          variant="outlined"
        >
          Confirm
        </Button>
        <Button
          onClick={() => closeDialog()}
          startIcon={<CloseOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[600],
            ":hover": {
              backgroundColor: colors.redAccent[700],
            },
          }}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignActions;
