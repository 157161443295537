import { convertToHTML, convertFromHTML } from "draft-convert";

const contentToHtml = (content) => {
  const html = convertToHTML({
    styleToHTML: (style) => {
      if (style === "BOLD") {
        return <span style={{ color: "blue" }} />;
      }
    },
    blockToHTML: (block) => {
      if (block.type === "PARAGRAPH") {
        return <p />;
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      return originalText;
    },
  })(content);
  return html;
};

const contentFromHtml = (html) => {
  const contentState = convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === "span" && node.style.color === "blue") {
        return currentStyle.add("BLUE");
      } else {
        return currentStyle;
      }
    },
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === "a") {
        return createEntity("LINK", "MUTABLE", { url: node.href });
      }
    },
    textToEntity: (text, createEntity) => {
      const result = [];
      text.replace(/(\w+)/g, (match, name, offset) => {
        const entityKey = createEntity("AT-MENTION", "IMMUTABLE", { name });
        result.push({
          entity: entityKey,
          offset,
          length: match.length,
          result: match,
        });
      });
      return result;
    },
    htmlToBlock: (nodeName, node) => {
      if (nodeName === "blockquote") {
        return {
          type: "blockquote",
          data: {},
        };
      }
    },
  })(html);
  return contentState;
};

const draftConvertService = {
  contentToHtml: contentToHtml,
  contentFromHtml: contentFromHtml,
};

export default draftConvertService;
