import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { axiosApiPacs } from "../api/axios";
import useAuth from "./useAuth";
import UrlSingleton from "../context/UrlSingleton";

const useAxiosApiPacs = () => {
  const refresh = useRefreshToken();
  const { auth, shareToken } = useAuth();
  const { urlPacsApi } = new UrlSingleton();

  useEffect(() => {
    const requestIntercept = axiosApiPacs.interceptors.request.use(
      (config) => {
        config.baseURL = urlPacsApi;
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${
            !auth?.token ? shareToken : auth?.token
          }`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosApiPacs.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axiosApiPacs(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosApiPacs.interceptors.request.eject(requestIntercept);
      axiosApiPacs.interceptors.response.eject(responseIntercept);
    };
  }, [auth, shareToken, urlPacsApi, refresh]);

  return axiosApiPacs;
};

export default useAxiosApiPacs;
