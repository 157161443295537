import { useEffect, useRef, useState } from "react";
import { tags } from "../../services/constant";
import utils from "../../utils";
import cornerstoneService from "../../services/cornerstoneService";
import { useTranslation } from "react-i18next";
import useAxiosApiPacs from "../../hooks/useAxiosApiPacs";
import Restricted from "../../components/Restricted";
import permission from "../../permission";
import { endPoint } from "../../api/axios";
import UrlSingleton from "../../context/UrlSingleton";
// import { useNavigate } from "react-router-dom";

import {
  Box,
  useTheme,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const Studies = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosApiPacs = useAxiosApiPacs();
  const { urlPacs } = new UrlSingleton();
  // const navigate = useNavigate();
  const focusRef = useRef();

  const [loading, setLoading] = useState(true);
  const [pageState, setPageState] = useState({
    data: [],
    totalRow: 0,
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const setFromDate = () => {
    let today = new Date();
    return utils.convertDate(
      new Date(today.setMonth(today.getMonth() - 3)),
      "yyyy-MM-dd"
    );
  };

  const [filter, setFilter] = useState({
    PatientID: "",
    PatientName: "",
    PatientBirthDate: "",
    StudyDate: "",
    FromDate: setFromDate(),
    ToDate: utils.convertDate(new Date(), "yyyy-MM-dd"),
    // StudyDescription: "",
    // ModalitiesInStudy: "",
    // AccessionNumber: "",
  });

  const columns = [
    {
      field: "PatientID",
      headerName: t("patient-id"),
      flex: 1,
    },
    {
      field: "PatientName",
      headerName: t("patient-name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "PatientBirthDate",
      headerName: t("patient-birth-date"),
      flex: 1,
      valueGetter: ({ value }) => {
        if (value) return utils.toViewerDate(value);
      },
    },
    {
      field: "StudyDate",
      headerName: t("study-date"),
      flex: 1,
      valueGetter: ({ value }) => {
        if (value) return utils.toViewerDate(value);
      },
    },
    // {
    //   field: "StudyDescription",
    //   headerName: t("study-description"),
    //   flex: 1,
    // },
    {
      field: "ModalitiesInStudy",
      headerName: t("modality"),
      flex: 0.3,
    },
    // {
    //   field: "AccessionNumber",
    //   headerName: t("accession-number"),
    //   flex: 1,
    // },
    // {
    //   field: "NumberOfStudyRelatedSeries",
    //   headerName: t("number-of-series"),
    //   flex: 1,
    // },
  ];

  useEffect(() => {
    focusRef.current?.focus();
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [paginationModel.pageSize, paginationModel.page]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let obj = {
        urlPacs: urlPacs,
        filter: filter,
        limit: paginationModel.pageSize,
        offset: paginationModel.pageSize * paginationModel.page,
        fieldInclude: [tags.StudyDescription],
      };

      const res = await axiosApiPacs.post(endPoint.Studies, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      const listData =
        res.data.length > 0
          ? res.data.map((data) => {
              return {
                PatientID: cornerstoneService.getTag(data, tags.PatientId),
                PatientName: cornerstoneService.getTag(data, tags.PatientName),
                PatientBirthDate: cornerstoneService.getTag(
                  data,
                  tags.PatientBirthDate
                ),
                StudyInstanceUID: cornerstoneService.getTag(
                  data,
                  tags.StudyInstanceUID
                ),
                // StudyDescription: cornerstoneService.getTag(
                //   data,
                //   tags.StudyDescription
                // ),
                StudyDate: cornerstoneService.getTag(data, tags.StudyDate),
                ModalitiesInStudy: cornerstoneService.getTag(
                  data,
                  tags.ModalitiesInStudy
                ),
                // NumberOfStudyRelatedSeries: cornerstoneService.getTag(
                //   data,
                //   tags.NumberOfStudyRelatedSeries
                // ),
                // AccessionNumber: cornerstoneService.getTag(
                //   data,
                //   tags.AccessionNumber
                // ),
              };
            })
          : [];

      let statisticUrl = endPoint.Studies + "/Statistics";
      const countRes = await axiosApiPacs.post(statisticUrl, urlPacs, {
        headers: {
          "Content-Type": "application/json;",
        },
      });
      setPageState({
        totalRow: countRes.data < 1 ? listData.length : countRes.data,
        data: listData,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleRowDoubleClick = async (e) => {
    let row = e.row;
    return openInNewTab(`/study/${row.StudyInstanceUID}`);
    // return navigate(`/study/${row.StudyInstanceUID}`);
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "FromDate" || name === "ToDate")
      value = e.target.value.replaceAll("-", "");

    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Header
        title={t("studyList")}
        subtitle="Double click on Study to view"
      ></Header>
      <Box
        display="flex"
        component="form"
        justifyContent="space-between"
        p={2}
        onSubmit={handleFilter}
      >
        <Restricted to={permission.list.study}>
          <TextField
            sx={{ ml: 2, flex: 1 }}
            placeholder={t("patient-id")}
            name="PatientID"
            size="small"
            variant="outlined"
            color="info"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            inputRef={focusRef}
          />
          <TextField
            sx={{ ml: 2, flex: 1 }}
            placeholder={t("patient-name")}
            name="PatientName"
            size="small"
            variant="outlined"
            color="info"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
          />
        </Restricted>
        <TextField
          sx={{ ml: 2, flex: 1 }}
          label={t("from-date")}
          name="FromDate"
          type="date"
          size="small"
          variant="outlined"
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={filter.FromDate}
          onChange={handleChange}
        />
        <TextField
          sx={{ ml: 2, flex: 1 }}
          label={t("to-date")}
          name="ToDate"
          type="date"
          size="small"
          variant="outlined"
          color="info"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={filter.ToDate}
          onChange={handleChange}
        />
        <Button
          sx={{
            background: colors.greenAccent[400],
            ":hover": {
              backgroundColor: colors.greenAccent[600],
            },
            marginLeft: 1,
          }}
          variant="outlined"
          startIcon={<SearchOutlinedIcon />}
          type="submit"
        >
          <Typography variant="p" sx={{ color: colors.primary[400] }}>
            {t("search")}
          </Typography>
        </Button>
      </Box>
      <Box>
        <Box
          m="0 10px 0 10px"
          height={"calc(100vh - 180px)"}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            columns={columns}
            rows={pageState.data}
            getRowId={(row) => row.StudyInstanceUID}
            rowCount={pageState.totalRow}
            paginationMode="server"
            pageSizeOptions={[10, 20, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            onRowDoubleClick={handleRowDoubleClick}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default Studies;
