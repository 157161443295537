import { createSlice } from "@reduxjs/toolkit";
import cornerstone from "cornerstone-core";

export const viewportGrid = createSlice({
  name: "viewportGrid",
  initialState: {
    rows: 1,
    columns: 1,
    numLayout: 1,
    viewports: [], //id:"" ,element: undefined, seriesId:"" ,fps: 10, isPlay: false,
    isResizeGrid: false,
  },
  reducers: {
    setViewportGrid: (state, action) => {
      const { rows, columns } = action.payload;
      state.rows = rows;
      state.columns = columns;
      state.numLayout = rows * columns;
      state.isResizeGrid = true;
    },
    addViewport: (state, action) => {
      const isFound = state.viewports.some((item) => {
        if (item.id === action.payload.id) {
          return true;
        }
        return false;
      });
      if (!isFound) state.viewports.push(action.payload);
    },
    removeViewport: (state, action) => {
      state.viewports = state.viewports.filter((item) => {
        return item.id !== action.payload.id;
      });
    },
    updateCineViewport: (state, action) => {
      const { fps, isPlay } = action.payload;
      var updatedArr = state.viewports.map((item) => {
        if (item.id === action.id)
          return {
            ...item,
            fps: fps,
            isPlay: isPlay,
          };
        return item;
      });
      state.viewports = updatedArr;
    },
    resizeViewports: (state) => {
      state.viewports.forEach((item) => {
        cornerstone.resize(item.element, true);
      });
      state.isResizeGrid = false;
    },
  },
});

export const {
  setViewportGrid,
  addViewport,
  removeViewport,
  updateCineViewport,
  resizeViewports,
} = viewportGrid.actions;
export default viewportGrid.reducer;
