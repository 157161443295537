import {
  Box,
  useTheme,
  Button,
  Select,
  MenuItem,
  Slider,
  TextField,
  ClickAwayListener,
  Tooltip,
  Collapse,
} from "@mui/material";
import { tokens } from "../../../theme";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import BrightnessMediumOutlinedIcon from "@mui/icons-material/BrightnessMediumOutlined";
import OpenWithOutlinedIcon from "@mui/icons-material/OpenWithOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import CallReceivedOutlinedIcon from "@mui/icons-material/CallReceivedOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import StopOutlinedIcon from "@mui/icons-material/StopOutlined";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import FirstPageOutlinedIcon from "@mui/icons-material/FirstPageOutlined";
import LastPageOutlinedIcon from "@mui/icons-material/LastPageOutlined";
import SettingsBrightnessOutlinedIcon from "@mui/icons-material/SettingsBrightnessOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PanToolAltOutlinedIcon from "@mui/icons-material/PanToolAltOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import ToolbarButton from "../../../components/ToolbarButton";

import { useEffect, useState, useRef } from "react";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { tools } from "../constant";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toggleSeries } from "../../../redux/seriesList";
import { toggleDiagnostic } from "../../../redux/diagnostic";
import { setViewportGrid } from "../../../redux/viewportGrid";
import { initCornerstoneTool } from "../../../init/initCornerstone";
import { endPoint } from "../../../api/axios";
import permission from "../../../permission";
import useAxiosAuth from "../../../hooks/useAxiosAuth";
import utils from "../../../utils";
import Restricted from "../../../components/Restricted";
import { SYSTEM_ID } from "../../../api/axios";
import "./css/ToolBar.css";

const DEFAULT_CINE = {
  fps: 10,
  isPlay: false,
};

const tableSettings = {
  maxRows: 3,
  maxCols: 3,
  emptyCell: {
    row: -1,
    col: -1,
  },
};

const ToolBar = ({ studyId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const activeViewport = useSelector((state) => state.activeViewport);
  const activeElement = activeViewport.element;
  const axios = useAxiosAuth();
  const dispatch = useDispatch();

  const [toolbarState, setToolbarState] = useState({
    showLayout: false,
    showCine: false,
    showDownload: false,
    showShare: false,
    showGeneral: false,
    showAnnotation: false,
  });

  const [cineState, setCineState] = useState(DEFAULT_CINE);
  const [shareLink, setShareLink] = useState({
    link: "",
    ttl: 900,
    tooltipOpen: false,
  });

  const fileNameRef = useRef();
  const fileExtensionRef = useRef();

  const [table, setTable] = useState({
    table: [[]],
    selectedCell: tableSettings.emptyCell,
  });

  const toolImport = cornerstoneTools.import;
  const scrollToIndex = toolImport("util/scrollToIndex");

  useEffect(() => {
    const addTools = () => {
      const globalTool = cornerstoneTools.store.state.globalTools;
      for (var i = 0; i < tools.length; i++) {
        let tool = tools[i];
        if (globalTool[tool.name]) continue;
        if (tool === cornerstoneTools.ZoomTool) {
          cornerstoneTools.addTool(tool, {
            configuration: {
              minScale: 0.1,
              maxScale: 20.0,
            },
          });
        } else if (tool === cornerstoneTools.TextMarkerTool) {
          cornerstoneTools.addTool(tool, {
            configuration: {
              markers: ["A", "B", "C", "D", "E"],
              current: "A",
              ascending: true,
              loop: false,
            },
          });
        } else {
          cornerstoneTools.addTool(tool);
        }
      }
    };

    const activeDefaultTools = () => {
      cornerstoneTools.setToolActive("Wwwc", {
        mouseButtonMask: 1,
      });
      cornerstoneTools.setToolActive("Zoom", {
        mouseButtonMask: 2,
      }); //middle
      cornerstoneTools.setToolActive("Pan", {
        mouseButtonMask: 4,
      }); // right
      cornerstoneTools.setToolActive("ZoomMouseWheel", {
        mouseButtonMask: 1,
      });
    };

    initCornerstoneTool();
    addTools();
    activeDefaultTools();
    highlightCells(tableSettings.emptyCell);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cineData = () => {
      if (activeElement?.nodeName) {
        let playState = cornerstoneTools.getToolState(
          activeElement,
          "playClip"
        );
        if (playState && playState.data?.length > 0) {
          return {
            fps: playState.data[0].framesPerSecond,
            isPlay: playState.data[0].intervalId > 0,
          };
        }
      }
      return DEFAULT_CINE;
    };
    let cine = cineData();
    setCineState((prevState) => ({
      ...prevState,
      fps: cine.fps,
      isPlay: cine.isPlay,
    }));
  }, [activeElement]);

  const setToolActive = (e) => {
    // if (
    //   cornerstoneTools.getToolForElement(activeElement, "Magnify")?.mode ===
    //   "active"
    // ) {
    //   cornerstoneTools.setToolPassive("Magnify");
    // }
    let toolName = e.currentTarget.name;
    cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
  };

  const reset = () => {
    cornerstone.reset(activeElement);
  };

  const clearAnnotation = () => {
    if (!activeElement?.nodeName) {
      return;
    }
    const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager;
    toolState.clear(activeElement);
    cornerstone.updateImage(activeElement);
  };

  const playClip = () => {
    try {
      const enabledElement = cornerstone.getEnabledElement(activeElement);
      if (!enabledElement || !enabledElement.image) {
        return;
      }
      cornerstoneTools.playClip(activeElement, cineState.fps);
      setCineState((prevState) => ({
        ...prevState,
        isPlay: true,
      }));
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const stopClip = () => {
    try {
      const enabledElement = cornerstone.getEnabledElement(activeElement);
      if (!enabledElement || !enabledElement.image) {
        return;
      }
      cornerstoneTools.stopClip(activeElement);
      setCineState((prevState) => ({
        ...prevState,
        isPlay: false,
      }));
    } catch (err) {
      return;
    }
  };

  const previousImage = () => {
    stopClip();
    const stackData = cornerstoneTools.getToolState(activeElement, "stack");
    if (!stackData || !stackData.data || !stackData.data.length) return;
    const { currentImageIdIndex } = stackData.data[0];
    if (currentImageIdIndex === 0) return;
    scrollToIndex(activeElement, currentImageIdIndex - 1);
  };

  const nextImage = () => {
    stopClip();
    const stackData = cornerstoneTools.getToolState(activeElement, "stack");
    if (!stackData || !stackData.data || !stackData.data.length) return;
    const { currentImageIdIndex, imageIds } = stackData.data[0];
    if (currentImageIdIndex >= imageIds.length - 1) return;
    scrollToIndex(activeElement, currentImageIdIndex + 1);
  };

  const toStart = () => {
    stopClip();
    const stackData = cornerstoneTools.getToolState(activeElement, "stack");
    if (!stackData || !stackData.data || !stackData.data.length) return;
    scrollToIndex(activeElement, 0);
  };

  const toEnd = () => {
    stopClip();
    const stackData = cornerstoneTools.getToolState(activeElement, "stack");
    if (!stackData || !stackData.data || !stackData.data.length) return;
    const lastIndex = stackData.data[0].imageIds.length - 1;
    scrollToIndex(activeElement, lastIndex);
  };

  const invertViewPort = () => {
    const viewport = cornerstone.getViewport(activeElement);
    viewport.invert = !viewport.invert;
    cornerstone.setViewport(activeElement, viewport);
  };

  const rotateRight = () => {
    const viewport = cornerstone.getViewport(activeElement);
    viewport.rotation += 90;
    cornerstone.setViewport(activeElement, viewport);
  };

  const flipH = () => {
    const viewport = cornerstone.getViewport(activeElement);
    viewport.hflip = !viewport.hflip;
    cornerstone.setViewport(activeElement, viewport);
  };

  const flipV = () => {
    const viewport = cornerstone.getViewport(activeElement);
    viewport.vflip = !viewport.vflip;
    cornerstone.setViewport(activeElement, viewport);
  };

  const saveImage = () => {
    let fileName = fileNameRef.current.value;
    let fileExtension = fileExtensionRef.current.value;
    cornerstoneTools.SaveAs(activeElement, fileName, `image/${fileExtension}`);
  };

  const highlightCells = (currentCell) => {
    let table = [];
    for (var row = 0; row < tableSettings.maxRows; row++) {
      let rowTable = [];
      for (let col = 0; col < tableSettings.maxCols; col++) {
        let cell = { row: row, col: col };
        if (isRange(cell, currentCell)) {
          cell.className = "hover";
        }
        rowTable.push(cell);
      }
      table.push(rowTable);
    }
    setTable((old) => ({
      ...old,
      table: table,
    }));
  };

  const isRange = (cell, parentCell) => {
    return cell.row <= parentCell?.row && cell.col <= parentCell?.col;
  };

  const setLayouts = (cell) => {
    let rows = cell.row + 1;
    let columns = cell.col + 1;
    dispatch(setViewportGrid({ rows: rows, columns: columns }));
    setToolbarState((prevState) => ({
      ...prevState,
      showLayout: false,
    }));
  };

  const createShareLink = async () => {
    try {
      let obj = {
        studyId: studyId,
        tokenTtl: shareLink.ttl,
        systemId: SYSTEM_ID,
      };
      const response = await axios.post(endPoint.ShareLink, obj);
      const token = response.data;
      let url = window.location.origin + `/share/${studyId}/${token}`;

      setShareLink((prev) => ({ ...prev, link: url }));
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  const copyShareLink = () => {
    navigator.clipboard.writeText(shareLink.link);
    setTooltipOpen(true);
  };

  const setTooltipOpen = (open: Boolean) => {
    setShareLink((prev) => ({ ...prev, tooltipOpen: open }));
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        height="3rem"
        sx={{ mb: "5px" }}
      >
        <Box borderRight="1px solid">
          <ToolbarButton
            icon={<ListOutlinedIcon fontSize="medium" />}
            handleClick={() => dispatch(toggleSeries())}
            label={t("series")}
          />
        </Box>
        <Box className="toolbar-list" borderRight="1px solid">
          <ToolbarButton
            icon={<WindowOutlinedIcon fontSize="medium" />}
            handleClick={() =>
              setToolbarState((prevState) => ({
                ...prevState,
                showLayout: !toolbarState.showLayout,
              }))
            }
            label={t("layout")}
          />
          <ToolbarButton
            icon={<MenuOutlinedIcon fontSize="medium" />}
            name="StackScroll"
            handleClick={(e) => setToolActive(e)}
            label={t("stack-scroll")}
          />
          <ToolbarButton
            icon={<OndemandVideoOutlinedIcon fontSize="medium" />}
            handleClick={() =>
              setToolbarState((prevState) => ({
                ...prevState,
                showCine: !toolbarState.showCine,
              }))
            }
            label={t("cine")}
          />
        </Box>
        <Box className="toolbar-list" borderRight="1px solid" display="flex">
          <ToolbarButton
            sx={{ marginTop: "0.35em" }}
            icon={<PanToolAltOutlinedIcon fontSize="medium" />}
            name="General Tools"
            handleClick={() =>
              setToolbarState((prev) => ({
                ...prev,
                showGeneral: !prev.showGeneral,
                showAnnotation: false,
              }))
            }
            label={t("General Tools")}
          />
          <Collapse orientation="horizontal" in={toolbarState.showGeneral}>
            <ToolbarButton
              icon={<SettingsBrightnessOutlinedIcon fontSize="medium" />}
              name="Wwwc"
              handleClick={(e) => setToolActive(e)}
              label={t("levels")}
            />
            <ToolbarButton
              icon={<SearchOutlinedIcon fontSize="medium" />}
              name="Zoom"
              handleClick={(e) => setToolActive(e)}
              label={t("zoom")}
            />
            <ToolbarButton
              icon={<OpenWithOutlinedIcon fontSize="medium" />}
              name="Pan"
              handleClick={(e) => setToolActive(e)}
              label={t("pan")}
            />
            <ToolbarButton
              icon={<ZoomInOutlinedIcon fontSize="medium" />}
              name="Magnify"
              handleClick={(e) => setToolActive(e)}
              label={t("magnify")}
            />
            <ToolbarButton
              icon={<BrightnessMediumOutlinedIcon fontSize="medium" />}
              name="Invert"
              handleClick={() => invertViewPort()}
              label={t("invert")}
            />
            <ToolbarButton
              icon={<RotateRightOutlinedIcon fontSize="medium" />}
              name="RotateRight"
              handleClick={() => rotateRight()}
              label={t("rotate-right")}
            />
            <ToolbarButton
              icon={<MoreHorizOutlinedIcon fontSize="medium" />}
              name="FlipH"
              handleClick={() => flipH()}
              label={t("flip-h")}
            />
            <ToolbarButton
              icon={<MoreVertOutlinedIcon fontSize="medium" />}
              name="FlipV"
              handleClick={() => flipV()}
              label={t("flip-v")}
            />
            <ToolbarButton
              icon={<ReplayOutlinedIcon fontSize="medium" />}
              name="Reset"
              handleClick={() => reset()}
              label={t("reset")}
            />
          </Collapse>
        </Box>
        <Box className="toolbar-list" borderRight="1px solid" display="flex">
          <ToolbarButton
            sx={{ marginTop: "0.35em" }}
            icon={<BrushOutlinedIcon fontSize="medium" />}
            name="Annotation Tools"
            handleClick={() =>
              setToolbarState((prev) => ({
                ...prev,
                showAnnotation: !prev.showAnnotation,
                showGeneral: false,
              }))
            }
            label={t("Annotation Tools")}
          />
          <Collapse orientation="horizontal" in={toolbarState.showAnnotation}>
            <ToolbarButton
              icon={<StraightenOutlinedIcon fontSize="medium" />}
              name="Length"
              handleClick={(e) => setToolActive(e)}
              label={t("length")}
            />
            <ToolbarButton
              icon={<CallReceivedOutlinedIcon fontSize="medium" />}
              name="ArrowAnnotate"
              handleClick={(e) => setToolActive(e)}
              label={t("annotate")}
            />
            <ToolbarButton
              icon={<ChevronLeftOutlinedIcon fontSize="medium" />}
              name="Angle"
              handleClick={(e) => setToolActive(e)}
              label={t("angle")}
            />
            <ToolbarButton
              icon={<DesignServicesOutlinedIcon fontSize="medium" />}
              name="Bidirectional"
              handleClick={(e) => setToolActive(e)}
              label={t("bidirectional")}
            />
            <ToolbarButton
              icon={<AdjustOutlinedIcon fontSize="medium" />}
              name="Probe"
              handleClick={(e) => setToolActive(e)}
              label={t("probe")}
            />
            <ToolbarButton
              icon={<CircleOutlinedIcon fontSize="medium" />}
              name="EllipticalRoi"
              handleClick={(e) => setToolActive(e)}
              label={t("elliptical")}
            />
            <ToolbarButton
              icon={<RectangleOutlinedIcon fontSize="medium" />}
              name="RectangleRoi"
              handleClick={(e) => setToolActive(e)}
              label={t("rectangle")}
            />
            <ToolbarButton
              icon={<FontAwesomeIcon icon={faEraser} />}
              name="Eraser"
              handleClick={(e) => setToolActive(e)}
              label={t("eraser")}
            />
            <ToolbarButton
              icon={<DeleteForeverOutlinedIcon fontSize="medium" />}
              name="Clear"
              handleClick={() => clearAnnotation()}
              label={t("clear")}
            />
          </Collapse>
        </Box>
        <Box>
          <ToolbarButton
            icon={<FileDownloadOutlinedIcon fontSize="medium" />}
            name="Download"
            handleClick={() =>
              setToolbarState((prevState) => ({
                ...prevState,
                showDownload: !toolbarState.showDownload,
                showShare: false,
              }))
            }
            label={t("save")}
          />
          <Restricted to={permission.create.shareLink}>
            <ToolbarButton
              icon={<ShareOutlinedIcon fontSize="medium" />}
              name="Share"
              handleClick={() =>
                setToolbarState((prevState) => ({
                  ...prevState,
                  showShare: !toolbarState.showShare,
                  showDownload: false,
                }))
              }
              label={t("share")}
            />
          </Restricted>
          <ToolbarButton
            icon={<AssignmentOutlinedIcon fontSize="medium" />}
            name="Diagnostic"
            handleClick={() => dispatch(toggleDiagnostic())}
            label={t("diagnostic")}
          />
        </Box>
      </Box>
      {/* Dialog */}
      <Box
        className="cineDialog"
        backgroundColor={colors.primary[400]}
        display={toolbarState.showCine ? "block" : "none"}
      >
        <Box>
          <Slider
            value={cineState.fps}
            step={1}
            min={1}
            max={60}
            sx={{
              color: colors.blueAccent[200],
            }}
            aria-label="Default"
            valueLabelDisplay="on"
            onChange={(e) => {
              stopClip();
              setCineState((prevState) => ({
                ...prevState,
                fps: e.target.value,
              }));
            }}
          />
        </Box>
        <Box>
          <ToolbarButton
            icon={
              <FirstPageOutlinedIcon
                fontSize="medium"
                titleAccess="Skip to first image"
              />
            }
            handleClick={() => toStart()}
          />
          <ToolbarButton
            icon={
              <SkipPreviousOutlinedIcon
                fontSize="medium"
                titleAccess="Previous image"
              />
            }
            handleClick={() => previousImage()}
          />
          <ToolbarButton
            icon={
              !cineState.isPlay ? (
                <PlayArrowOutlinedIcon fontSize="medium" titleAccess="Play" />
              ) : (
                <StopOutlinedIcon fontSize="medium" titleAccess="Stop" />
              )
            }
            handleClick={() => (cineState.isPlay ? stopClip() : playClip())}
          />
          <ToolbarButton
            icon={
              <SkipNextOutlinedIcon
                fontSize="medium"
                titleAccess="Next image"
              />
            }
            handleClick={() => nextImage()}
          />
          <ToolbarButton
            icon={
              <LastPageOutlinedIcon
                fontSize="medium"
                titleAccess="Skip to last image"
              />
            }
            handleClick={() => toEnd()}
          />
        </Box>
      </Box>
      <Box
        className="downloadDialog"
        backgroundColor={colors.primary[400]}
        display={toolbarState.showDownload ? "block" : "none"}
      >
        <Box borderRadius="3px" display="flex">
          <TextField
            sx={{ flex: 1 }}
            placeholder="File Name"
            inputRef={fileNameRef}
          />
          <Select
            defaultValue="jpeg"
            inputRef={fileExtensionRef}
            label="File Type"
          >
            <MenuItem value="jpeg">jpeg</MenuItem>
            <MenuItem value="png">png</MenuItem>
          </Select>
          <Button
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={() => saveImage()}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box
        className="downloadDialog"
        backgroundColor={colors.primary[400]}
        display={toolbarState.showShare ? "block" : "none"}
      >
        <Box borderRadius="3px" display="flex">
          <Select
            value={shareLink.ttl}
            onChange={(e) => {
              setShareLink((prev) => ({
                ...prev,
                ttl: e.target.value,
              }));
            }}
          >
            <MenuItem value="900">15 Minutes</MenuItem>
            <MenuItem value="1800">30 Minutes</MenuItem>
            <MenuItem value="3600">1 Hour</MenuItem>
            <MenuItem value="7200">2 Hours</MenuItem>
            <MenuItem value="14400">4 Hours</MenuItem>
            <MenuItem value="28800">8 Hours</MenuItem>
            <MenuItem value="86400">1 Day</MenuItem>
          </Select>
          <Button
            sx={{
              color: colors.grey[100],
              backgroundColor: colors.greenAccent[600],
              ":hover": {
                backgroundColor: colors.greenAccent[700],
              },
            }}
            startIcon={<ShareOutlinedIcon />}
            onClick={() => createShareLink()}
          >
            Create Link
          </Button>
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip
              open={shareLink.tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Coppied to clipboard"
            >
              <Button
                sx={{
                  color: colors.grey[100],
                  backgroundColor: colors.blueAccent[600],
                  ":hover": {
                    backgroundColor: colors.blueAccent[700],
                  },
                }}
                startIcon={<ContentCopyOutlinedIcon />}
                onClick={() => copyShareLink()}
              >
                Copy Link
              </Button>
            </Tooltip>
          </ClickAwayListener>
        </Box>
        <TextField
          fullWidth
          sx={{ display: shareLink.link.length > 0 ? "block" : "none" }}
          placeholder="Link"
          value={shareLink.link}
          inputProps={{ readOnly: true }}
        />
      </Box>
      <Box
        className="layoutDialog"
        backgroundColor={colors.primary[100]}
        display={toolbarState.showLayout ? "block" : "none"}
        sx={{
          "& .hover": {
            backgroundColor: `${colors.blueAccent[500]}`,
          },
        }}
      >
        <table>
          <tbody>
            {table.table.map((row, i) => {
              return (
                <tr key={i}>
                  {row?.map((cell, j) => {
                    return (
                      <td
                        className={cell.className}
                        style={{
                          width: "20px",
                          height: "20px",
                          border: "solid 1px black",
                        }}
                        key={j}
                        onMouseEnter={() => highlightCells(cell)}
                        onMouseLeave={() =>
                          highlightCells(tableSettings.emptyCell)
                        }
                        onClick={() => setLayouts(cell)}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </>
  );
};

export default ToolBar;
