import { createSlice } from "@reduxjs/toolkit";

export const seriesListSlice = createSlice({
  name: "seriesList",
  initialState: {
    isOpen: true,
    activeSeries: "",
    isFetchImage: false,
  },
  reducers: {
    toggleSeries: (state) => {
      state.isOpen = !state.isOpen;
    },
    setCollapseSeries: (state, action) => {
      state.isOpen = action.payload;
    },
    setIsFetchImage: (state, action) => {
      state.isFetchImage = action.payload;
    },
    setActiveSeries: (state, action) => {
      const { id, isFetchImage } = action.payload;
      state.activeSeries = id;
      state.isFetchImage = isFetchImage;
    },
  },
});

export const {
  toggleSeries,
  setCollapseSeries,
  setActiveSeries,
  setIsFetchImage,
} = seriesListSlice.actions;
export default seriesListSlice.reducer;
