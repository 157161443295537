import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  useTheme,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CardItem from "./CardItem";
import { tokens } from "../../../theme";
import { useState } from "react";
import { endPoint } from "../../../api/axios";
import useAxiosAdmin from "../../../hooks/useAxiosAdmin";
import utils from "../../../utils";

const AssignRoles = ({ data, setData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [query, setQuery] = useState("");
  const [searchParam] = useState(["functionName"]);
  const [selected, setSelected] = useState("");
  const axios = useAxiosAdmin();

  const handleSubmit = async () => {
    let roleIds = data.roles
      .filter((value) => {
        return value.checked;
      })
      .map((value) => {
        return value.roleId;
      });

    let obj = {
      accountId: data.accountId,
      roleId: roleIds,
    };

    try {
      let url = endPoint.AccountInfo + "/AssignRoles";
      const response = await axios.put(url, obj, {
        headers: {
          "Content-Type": "application/json;",
        },
      });

      if (response.status === 200) {
        utils.showMessage("Assign Roles Succeeded", "success");
        closeDialog();
      }
    } catch (err) {
      if (!err?.response) {
        utils.showMessage("Server not response", "error");
      } else {
        utils.showMessage(err.response.data, "error");
      }
      console.log(err);
    }
  };

  const handleCheck = (e) => {
    let checked = e.target.checked;
    let roleId = e.target.id;
    let newArr = [];
    newArr = data.roles.map((obj) => {
      if (obj.roleId === roleId) return { ...obj, checked: checked };
      return obj;
    });

    setData((prev) => ({
      ...prev,
      roles: newArr,
    }));
  };

  const closeDialog = () => {
    setData((prev) => ({
      ...prev,
      open: false,
      accountId: "",
      username: "",
      roles: [],
    }));
    setSelected("");
    setQuery("");
  };

  return (
    <Dialog open={data.open} maxWidth="lg" fullWidth>
      <DialogTitle variant="h3">
        Assign Roles For Account : {data.username}
      </DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={() => closeDialog()}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{
          "& .MuiCheckbox-root.Mui-checked": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box
          p={1}
          sx={{
            "& label.Mui-focused": {
              color: `${colors.primary[100]}`,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: `${colors.primary[100]}`,
              },
              "&:hover fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
              "&.Mui-focused fieldset": {
                borderColor: `${colors.primary[200]}`,
              },
            },
          }}
        >
          <TextField
            label="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            {utils
              .search(data.roles, searchParam, query)
              .map((value, index) => {
                return (
                  <Grid item xs={4} sm={4} md={4} key={index}>
                    <CardItem
                      key={index}
                      index={index}
                      selected={selected}
                      setSelected={setSelected}
                      functionsByRole={value}
                      handleCheck={handleCheck}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleSubmit()}
          startIcon={<CheckOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.greenAccent[600],
            ":hover": {
              backgroundColor: colors.greenAccent[700],
            },
          }}
          variant="outlined"
        >
          Confirm
        </Button>
        <Button
          onClick={() => closeDialog()}
          startIcon={<CloseOutlinedIcon />}
          sx={{
            color: colors.grey[100],
            backgroundColor: colors.redAccent[600],
            ":hover": {
              backgroundColor: colors.redAccent[700],
            },
          }}
          variant="outlined"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignRoles;
