import cornerstone from "cornerstone-core";
import utils from "../../../utils";

import "./css/ViewportOverlay.css";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const ViewportOverlay = ({
  imageIndex,
  stackSize,
  scale,
  windowWidth,
  windowCenter,
  imageId,
}) => {
  const [overlayData, setOverlayData] = useState({
    patientId: "",
    patientName: "",
    studyDescription: "",
    studyDate: undefined,
    studyTime: undefined,
    zoomPercentage: 0,
    wwwc: "",
    compression: "",
    seriesNumber: 0,
    instanceNumber: 0,
    frameRate: 0,
    imageDimensions: undefined,
    sliceLocation: 0,
    sliceThickness: 0,
    seriesDescription: "",
  });

  useEffect(() => {
    const updateOverlay = () => {
      const seriesMetadata =
        cornerstone.metaData.get("generalSeriesModule", imageId) || {};
      const imagePlaneModule =
        cornerstone.metaData.get("imagePlaneModule", imageId) || {};
      const generalStudyModule =
        cornerstone.metaData.get("generalStudyModule", imageId) || {};
      const patientModule =
        cornerstone.metaData.get("patientModule", imageId) || {};
      const generalImageModule =
        cornerstone.metaData.get("generalImageModule", imageId) || {};
      const cineModule = cornerstone.metaData.get("cineModule", imageId) || {};

      const { rows, columns } = imagePlaneModule;
      const { frameTime } = cineModule;
      const frameRate = utils.formatNumberPrecision(1000 / frameTime, 1);
      const compression = getCompression(imageId);
      let zoomPercentage = utils.formatNumberPrecision(scale * 100, 0);
      let wwwc = `W: ${
        windowWidth.toFixed ? windowWidth.toFixed(0) : windowWidth
      } L: ${windowWidth.toFixed ? windowCenter.toFixed(0) : windowCenter}`;
      let imageDimensions = `${columns} x ${rows}`;

      setOverlayData((prev) => ({
        ...prev,
        patientId: patientModule.patientId,
        patientName: patientModule.patientName,
        studyDescription: generalStudyModule.studyDescription,
        studyDate: generalStudyModule.studyDate,
        studyTime: generalStudyModule.studyTime,
        zoomPercentage: zoomPercentage,
        wwwc: wwwc,
        compression: compression,
        seriesNumber: seriesMetadata.seriesNumber,
        instanceNumber: generalImageModule.instanceNumber,
        frameRate: frameRate,
        imageDimensions: imageDimensions,
        sliceLocation: imagePlaneModule.sliceLocation,
        sliceThickness: imagePlaneModule.sliceThickness,
        seriesDescription: seriesMetadata.seriesDescription,
      }));
    };
    if (imageId && windowCenter && windowWidth && scale) updateOverlay();
  }, [imageId, scale, windowCenter, windowWidth]);

  const getCompression = (imageId) => {
    const generalImageModule =
      cornerstone.metaData.get("generalImageModule", imageId) || {};
    const {
      lossyImageCompression,
      lossyImageCompressionRatio,
      lossyImageCompressionMethod,
    } = generalImageModule;

    if (lossyImageCompression === "01" && lossyImageCompressionRatio !== "") {
      const compressionMethod = lossyImageCompressionMethod || "Lossy: ";
      const compressionRatio = utils.formatNumberPrecision(
        lossyImageCompressionRatio,
        2
      );
      return compressionMethod + compressionRatio + " : 1";
    }

    return "Lossless / Uncompressed";
  };

  return !imageId ? (
    <></>
  ) : (
    <Box className="ViewportOverlay">
      <Box className="top-left overlay-element">
        <Typography>
          {utils.formatPatientName(overlayData.patientName)}
        </Typography>
        <Typography>{overlayData.patientId}</Typography>
      </Box>
      <Box className="top-right overlay-element">
        <Typography>{overlayData.studyDescription}</Typography>
        <Typography>
          {utils.formatDate(overlayData.studyDate)}{" "}
          {utils.formatTime(overlayData.studyTime)}
        </Typography>
      </Box>
      <Box className="bottom-right overlay-element">
        <Typography>Zoom: {overlayData.zoomPercentage}%</Typography>
        <Typography>{overlayData.wwwc}</Typography>
        <Typography>{overlayData.compression}</Typography>
      </Box>
      <Box className="bottom-left overlay-element">
        <Typography>
          {overlayData.seriesNumber >= 0
            ? `Ser: ${overlayData.seriesNumber}`
            : ""}
        </Typography>
        <Typography>
          {stackSize > 1
            ? `Img: ${overlayData.instanceNumber} ${imageIndex}/${stackSize}`
            : ""}
        </Typography>
        <Typography>
          {" "}
          {overlayData.frameRate >= 0
            ? `${utils.formatNumberPrecision(overlayData.frameRate, 2)} FPS`
            : ""}
        </Typography>
        <Typography>{overlayData.imageDimensions}</Typography>
        <Typography>
          {utils.isValidNumber(overlayData.sliceLocation)
            ? `Loc: ${utils.formatNumberPrecision(
                overlayData.sliceLocation,
                2
              )} mm `
            : ""}
          {overlayData.sliceThickness
            ? `Thick: ${utils.formatNumberPrecision(
                overlayData.sliceThickness,
                2
              )} mm`
            : ""}
        </Typography>
        <Typography>{overlayData.seriesDescription}</Typography>
      </Box>
    </Box>
  );
};

export default ViewportOverlay;
