import useAuth from "./useAuth";
import { axiosAuth, endPoint } from "../api/axios";
import { useNavigate } from "react-router-dom";
import utils from "../utils";
import UrlSingleton from "../context/UrlSingleton";

const useShareToken = () => {
  const { setShareToken } = useAuth();
  const navigate = useNavigate();
  const validate = async (studyId, token) => {
    try {
      let url = endPoint.ShareLink + "/Validate";
      let obj = {
        studyId: studyId,
        token: token,
      };
      debugger;
      const response = await axiosAuth.post(url, obj);
      if (response.status === 200) {
        const data = response.data;
        setShareToken(token);
        new UrlSingleton(data.urlPacs, data.urlPacsApi);
      }
      return token;
    } catch (error) {
      if (error?.response.status === 401) {
        utils.showMessage(
          "This link has expired. Please login and create new link.",
          "error"
        );
        navigate("/login", { replace: true });
      }
    }
  };

  return validate;
};

export default useShareToken;
