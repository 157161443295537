export default class UrlSingleton {
  constructor(urlPacs = "", urlPacsApi = "") {
    if (UrlSingleton.instance) {
      return UrlSingleton.instance;
    }

    this.urlPacs = urlPacs;
    this.urlPacsApi = urlPacsApi;
    UrlSingleton.instance = this;

    return this;
  }
}
