import { useState } from "react";

import { Box, Tabs, Tab } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import Header from "../../components/Header";
import ActionsGrid from "./ActionsGrid";
import FunctionsGrid from "./FunctionsGrid";
import SmtpSetting from "./SmtpSetting";
import RolesGrid from "./RolesGrid";
import AccountsGrid from "./AccountsGrid";
import DiagnosticResultTemplate from "./DiagnosticResultTemplate";

const SystemSetting = () => {
  const [tab, setTab] = useState(0);

  const handleChangeTab = (e, value) => {
    setTab(value);
  };

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tab-panel-${index}`,
    };
  };

  return (
    <Box padding="10px" boxSizing="border-box">
      <Header title="System Management" />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="System Management Tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Diagnostic Result Template" {...tabProps(0)} />
          <Tab label="Actions" {...tabProps(1)} />
          <Tab label="Functions" {...tabProps(2)} />
          <Tab label="Roles" {...tabProps(3)} />
          <Tab label="Accounts" {...tabProps(4)} />
          <Tab label="SMTP Setting" {...tabProps(5)} />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <DiagnosticResultTemplate />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ActionsGrid />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <FunctionsGrid />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <RolesGrid />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <AccountsGrid />
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <SmtpSetting />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default SystemSetting;
