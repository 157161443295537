import { useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftConvertService from "../../../services/draftConvertService";
// import { convertToHTML, convertFromHTML } from "draft-convert";
import DOMPurify from "dompurify";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { tokens } from "../../../theme";
import { Box, Button, useTheme, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import format from "date-fns/format";
import TemplateDropdown from "./TemplateDropdown";
import utils from "../../../utils";

const RichTextEditor = ({
  data,
  defaultEdit,
  toolbar,
  onSave,
  onDelete,
  onCancel,
}) => {
  debugger;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isEdit, setIsEdit] = useState(defaultEdit);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      draftConvertService.contentFromHtml(data?.content)
    )
  );

  const editorStyle = {
    backgroundColor: colors.primary[400],
    color: colors.grey[100],
    border: `1px solid ${colors.primary[100]}`,
    minHeight: "20vh",
  };

  const toolbarStyle = {
    backgroundColor: colors.primary[400],
    color: colors.grey[500],
    border: `1px solid ${colors.primary[100]}`,
  };

  const createMarkup = (html) => {
    if (html)
      return {
        __html: DOMPurify.sanitize(html),
      };

    return {
      __html: "",
    };
  };

  const handleSave = () => {
    let content = editorState.getCurrentContent();
    if (!content || content.size < 1) return;
    let htmlContent = DOMPurify.sanitize(
      draftConvertService.contentToHtml(content)
    );
    let obj = {
      resultId: data.resultId,
      content: htmlContent,
    };
    onSave(obj);
    setIsEdit(false);
  };

  const handleTemplateChange = (templateContent) => {
    setEditorState(
      EditorState.createWithContent(
        draftConvertService.contentFromHtml(templateContent)
      )
    );
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setEditorState(
        EditorState.createWithContent(
          draftConvertService.contentFromHtml(data?.content)
        )
      );
      setIsEdit(false);
    }
  };

  return (
    <>
      {isEdit ? (
        <Box
          sx={{
            padding: "5px",
            border: `1px solid ${colors.primary[100]}`,
          }}
        >
          <Box p={1}>
            <TemplateDropdown callBack={handleTemplateChange} />
          </Box>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            editorStyle={editorStyle}
            toolbarStyle={toolbarStyle}
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
            toolbar={toolbar}
          />
          <Box paddingTop={1}>
            <Button
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.greenAccent[600],
                ":hover": {
                  backgroundColor: colors.greenAccent[700],
                },
              }}
              variant="outlined"
              startIcon={<CheckOutlinedIcon />}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              sx={{
                color: colors.grey[100],
                backgroundColor: colors.redAccent[600],
                ":hover": {
                  backgroundColor: colors.redAccent[700],
                },
              }}
              variant="outlined"
              startIcon={<CloseOutlinedIcon />}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <Box p={1} sx={{ border: `1px solid ${colors.primary[100]}` }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              Created Date:{" "}
              {utils.isValidString(data?.createdDate)
                ? format(new Date(data?.createdDate), "MM/dd/yyyy")
                : ""}
            </Typography>
            <Typography variant="h6">Created By : {data?.createdBy}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              variant="div"
              dangerouslySetInnerHTML={createMarkup(data?.content)}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default RichTextEditor;
