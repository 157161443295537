import { configureStore } from "@reduxjs/toolkit";
import confirmSliceReducer from "./confirm";
import seriesListSliceReducer from "./seriesList";
import viewportGridSliceReducer from "./viewportGrid";
import actionViewportSliceReducer from "./activeViewport";
import diagnosticSliceReducer from "./diagnostic";

export const store = configureStore({
  reducer: {
    confirmDialog: confirmSliceReducer,
    seriesList: seriesListSliceReducer,
    viewportGrid: viewportGridSliceReducer,
    activeViewport: actionViewportSliceReducer,
    diagnostic: diagnosticSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
